import Cookies from 'universal-cookie';
import env from './../../environment';
import Constant from './../../store/constant';
import store from './../../store/index';
import languageKapiten from './../libs/lang';
import { createIntl, createIntlCache } from 'react-intl';
import HtmlParser from 'react-html-parser';
import React from 'react';

const cookies = new Cookies();
var CryptoJS = require("crypto-js");


export function getDeviceLanguage() {
  const deviceLanguage = getUserParameter(Constant.paramsKey.language);
  const locale = ['en', 'th', 'id'].includes(deviceLanguage)
    ? deviceLanguage
    : 'en';
  return locale;
}

export function convertNewlinesToBr(text) {
  return text.split('\n').map((line, index, array) => (index === array.length - 1) ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>);
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();
const initLocale = getDeviceLanguage();

export let intl = createIntl(
  {
    locale: initLocale,
    messages: languageKapiten(initLocale),
  },
  cache,
);

function listener() {
  const { locale } = store.getState().localeReducer;

  if (locale !== intl.locale) {
    intl = createIntl(
      {
        locale,
        messages: languageKapiten(locale),
      },
      cache,
    );
  }
}

store.subscribe(listener);


export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}


export function changeStatusName(text) {
  var json = {
    Contract: 'contract',
    Checking: 'checking',
    Laycan: 'laycan',
    Loading: 'barge',
    Delivery: 'delivery',
    Unloading: 'unloading',
    Done: 'done',
  }
  if (text in json) {
    return json[text]
  } else {
    return false;
  }
}

export function getWordTrxCode(inputString) {

  // Pisahkan string menjadi array kata-kata
  let wordsresult = "";
  const words = inputString.split(' ');


  // Periksa panjang kata pertama
  if (words[0].length > 2) {
    wordsresult = words[0].toUpperCase();  // Kata pertama cukup panjang
  } else if (words.length > 1) {
    wordsresult = words[1].toUpperCase();  // Kata kedua jika ada
  } else {
    wordsresult = ''; // Jika tidak ada kata kedua, kembalikan string kosong
  }

  const now = new Date();

  // Ambil tahun, bulan, dan hari
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() dimulai dari 0
  const day = String(now.getDate()).padStart(2, '0');

  return `${wordsresult}/${year}${month}${day}/`;
}





export function parseJSON(params) {
  try {
    if (params) {
      var parse = JSON.parse(params.toString());
      return parse;
    }
    return false;

  } catch (e) {
    return false;
  }
}

export function roleOps(params) {
  try {
    if (params === 4) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
}

export function encryptData(params) {
  try {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(params), env.secretKey).toString();
    return ciphertext;
  } catch (e) {
    return false;
  }
}
export function decryptData(paramsCipertext) {
  try {
    var bytes = CryptoJS.AES.decrypt(paramsCipertext, env.secretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (e) {
    return false;
  }
}

export function decryptURL(param) {
  try {
    let secKey = env.secretKey.slice(0, 16);
    let key = CryptoJS.enc.Utf8.parse(secKey);
    let paramnya = decodeURIComponent(param);
    var decrypted = CryptoJS.AES.decrypt(paramnya, key, { mode: CryptoJS.mode.ECB });
    return decrypted.toString(CryptoJS.enc.Utf8)
  } catch (e) {
    return false;
  }
}

export function insertDashAtMultiplesOf4(inputString) {
  let result = "";

  try {

    for (let i = 0; i < inputString.length; i++) {
      result += inputString[i];

      if ((i + 1) % 4 === 0 && i !== inputString.length - 1) {
        result += " ";
      }
    }
  } catch (e) {

    return "";
  }

  return result;
}
export function customText(text, value) {
  const parts = text.split('<x>'); // Memisahkan teks menjadi bagian yang sesuai dengan penggantian
  return (
    <span>
      {HtmlParser(parts[0])}
      {value}
      {HtmlParser(parts[1])}
    </span>
  );
}



export function fileToDataUri(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e)
      return null;
    }
  })
}

export function fileToDataImg(data) {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.src = data;
      if (image.src) {
        image.onload = (event) => {
          resolve(event)
        };
      }
      resolve(data);
    } catch (e) {
      reject(e)
      return null;
    }
  })
}

export function isNotImageByUrl(url) {
  try {
    const filename = url.split('/').pop(); // Mengambil nama file dari URL
    const fileExtension = filename.split('.').pop().toLowerCase(); // Mengambil ekstensi dan mengonversinya menjadi huruf kecil

    // Daftar ekstensi yang dianggap sebagai gambar (gantilah sesuai kebutuhan Anda)
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    // Memeriksa apakah ekstensi file ada dalam daftar ekstensi gambar
    return imageExtensions.includes(fileExtension);
  } catch (error) {
    console.log(error)
  }

}

/**
 * set user parameter
 */
export function setUserParameter(paramName, paramValue) {
  return window.sessionStorage.setItem(paramName, paramValue);
}

/**
 * get user parameter
 */
export function getUserParameter(paramName) {
  return window.sessionStorage.getItem(paramName);
}

/**
 * remove user parameter
 */
export function removeUserParameter(paramName) {
  return window.sessionStorage.removeItem(paramName);
}



/**
 * set user parameter LOCAL STORAGE
 */
export function setUserParameterLocal(paramName, paramValue) {
  return window.localStorage.setItem(paramName, paramValue);
}

/**
 * get user parameter LOCAL STORAGE
 */
export function getUserParameterLocal(paramName) {
  return window.localStorage.getItem(paramName);
}

/**
 * remove user parameter LOCAL STORAGE
 */
export function removeUserParameterLocal(paramName) {
  console.log(paramName)
  return window.localStorage.removeItem(paramName);
}



export function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}



export function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371;  // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;  // Distance in kilometers
  return parseInt(distance);
}

function kilometersToNauticalMiles(kilometers) {
  return kilometers / 1.852;
}

export function calculateDistanceInNM(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius bumi dalam kilometer
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceInKm = R * c; // Jarak dalam kilometer
  const distanceInNM = kilometersToNauticalMiles(distanceInKm); // Konversi ke nautical miles

  return parseFloat(distanceInNM.toFixed(2));
}

// COOKIES

/**
 * set user parameter
 */
export function setUserParameterCk(paramName, paramValue, expired) {
  let options = {
    httpOnly: false,
    secure: false,
    path: '/'
  };
  if (expired) {
    options = {
      path: '/',
      priority: 'High',
      httpOnly: false,
      secure: false,
      expires: expired
    };
  }
  cookies.set(paramName, paramValue, options);
}

/**
 * get user parameter
 */
export function getUserParameterCk(paramName) {
  try {
    return cookies.get(paramName);
  } catch (e) {
    console.log(e)
    return false;
  }
}

/**
 * remove user parameter
 */
export function removeUserParameterCk(paramName) {
  let options = {
    httpOnly: false,
    secure: false,
    path: '/'
  };

  cookies.remove(paramName, options);

}

export function merge(a, b, prop) {
  var reduced = a.filter(function (aitem) {
    return !b.find(function (bitem) {
      return aitem[prop] === bitem[prop];
    });
  });
  return reduced.concat(b);
}

export function getLink(bucket_name, fileName) {
  if (bucket_name !== null) {
    return env.s3Link + '/' + bucket_name + '/' + fileName;
  } else {
    return null;
  }

}

export function kFormatter(num) {
  if (Math.abs(num) > 999999999999) { // 1T
    return Math.sign(num) * ((Math.abs(num) / 1000000000000).toFixed(1)) + 'T'
  } else if (Math.abs(num) > 999999999) { // 1M
    return Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1)) + 'B'
  } else if (Math.abs(num) > 999999) { // 1Jt
    return Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M'
  } else if (Math.abs(num) > 999) { //Seribu
    return Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'
  }
  else {
    return Math.sign(num) * Math.abs(num)
  }
  // return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}
export function formatNumber(num) {
  var nf = new Intl.NumberFormat();
  return nf.format(num);
}

export function formatNumberPersen(num) {
  var nf = new Intl.NumberFormat();
  return nf.format(num.toFixed(2));
}
export function inputnumberComma(num) {
  const cleaner = num.replace(/,/g,'');
  const number = parseFloat(cleaner)
  return number.toFixed(2);
}


export function formatNumberV(num) {
  let number = num;
  if (number >= 1000000) {
    let numberString = parseInt(number).toString();
    // Mengganti 3 angka dibelakang menjadi '000'
    let formattedNumberString = numberString.slice(0, -3) + '000';
    let formattedNumber = parseInt(formattedNumberString);

    return formattedNumber;

  }
  if (number > 10000 && number < 1000000) {
    let numberString = parseInt(number).toString();
    // Mengganti 3 angka dibelakang menjadi '000'
    let formattedNumberString = numberString.slice(0, -3) + '000';
    let formattedNumber = parseInt(formattedNumberString);

    return formattedNumber;
  }

  return parseInt(number);
}


export function kFormatterN(num) {
  if (Math.abs(num) > 999999999999) { // 1T
    return Math.sign(num) * ((Math.abs(num) / 1000000000000).toFixed(1))
  } else if (Math.abs(num) > 999999999) { // 1M
    return Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1))
  } else if (Math.abs(num) > 999999) { // 1Jt
    return Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1))
  } else if (Math.abs(num) > 999) { //Seribu
    return Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))
  }
  else {
    return Math.sign(num) * Math.abs(num)
  }
  // return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}



export function getCurrentDate(date='datetime', str) {
  let now = new Date();
  try {
    if (str) {
      now= new Date(str);
    }
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    if (date === 'datetime') {
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
    return `${year}-${month}-${day}`;
  } catch (error) {
    return false;
  }

}


export function rgbToHex(rgb) {
  // Memisahkan nilai RGB menjadi komponen Red, Green, dan Blue
  var rgbArray = rgb.match(/\d+/g);

  if (!rgbArray || rgbArray.length !== 3) {
    return rgb; // RGB tidak valid
  }

  // Mengonversi nilai RGB ke HEX
  var r = parseInt(rgbArray[0]);
  var g = parseInt(rgbArray[1]);
  var b = parseInt(rgbArray[2]);

  var hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return '#' + hex;
}


export function calculateTotalDistance(unit, coordArray) {
  let totalDistance = 0;
  let datanya = [];
  for (let i = 0; i < coordArray.length - 1; i++) {
    if (coordArray.length > 1) {
      const currentPair = [coordArray[i], coordArray[i + 1]];

      totalDistance += haversineDistance(coordArray[i], coordArray[i + 1], unit);
      datanya.push(currentPair);

    }

  }

  // console.log(totalDistance, unit)

  return totalDistance.toFixed(2);
};


export function haversineDistance(coord1, coord2, unit = 'km') {
  const toRad = (value) => (value * Math.PI) / 180;


  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  const R = 6371; // Radius bumi dalam kilometer

  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  // Konversi ke mil jika unit adalah 'mi' atau Nauticamiles

  return unit === 'mi' ? distance / 1.60934 : distance;

};

export function unitCalculate(distance = 0.00, unit = 'km') {

  if (unit === 'mi') {
    return (distance * 1.60934).toFixed(2);
  }
  if (unit === 'NM') {
    return (distance * 0.539957).toFixed(2);

  }
  if (unit === 'km') {

    return distance;

  }

}

export function convertHoursRounding(value) {
  // Hitung jumlah hari
  // Pisahkan jam dan menit dari total waktu desimal
  const hours = Math.floor(value);
  const decimalPart = value - hours;
  const minutes = Math.round(decimalPart * 60);

  // Logika pembulatan berdasarkan menit
  let finalHours = hours;

  if (minutes > 30) {
      finalHours += 1; // Tambah 1 jam jika menit lebih dari 40
  }


  return finalHours;

}







import Moment from 'moment';

var CryptoJS = require("crypto-js");
const SECRET_KEY = 'SiapaYangMengejarAkhiratMakaDuniaAkanMengikutinya'
const date = new Date();
const dateHours = Moment(date).format('YYYY-MM-DD HH:00:00')
const merge = SECRET_KEY + dateHours;

var rawStr = CryptoJS.enc.Utf8.parse(merge)
var base64 = CryptoJS.enc.Base64.stringify(rawStr);


export const authEncrypt = CryptoJS.MD5(base64);
export const apiURL ='https://fire.kapiten.id/';
// export const apiURL ='http://localhost:9005/';
export const firebaseDb = {
    keyMsg: 'BF_LGD9eveKY5G_NszyiyWSH_-leEWCdCOz9QduXdheOLzfx2TKe4ijwgiBiUA636TPwmztgnmVSmNcVEXlvtw0',
    apiKey: "AIzaSyCaJRbybhDkSzrU2c2McHCzbiu2GEgWcsc",
    authDomain: "app-bidding.firebaseapp.com",
    databaseURL: "https://app-bidding-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "app-bidding",
    storageBucket: "app-bidding.appspot.com",
    messagingSenderId: "161620176789",
    appId: "1:161620176789:web:1fd676a9d5a91326ae909c"
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    conFirestore: firebaseDb,
    version: '1.0.6',
    apiURL:  apiURL,
    s3Link: 'https://s3-id-jkt-1.kilatstorage.id',
    secretKey: 'SiapaYangMengejarAkhiratMakaDuniaAkanMengikutinya',
    auth: authEncrypt
};

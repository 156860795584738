
import env from '../../environment';
import axios from "axios";
import { getUserParameterCk, setUserParameterCk } from './index';
import Constant from '../../store/constant';
import { dataApi } from './../helpers/authentication';
// import { sentrySEND } from './../helpers/sentry';
// import Cookies from 'js-cookie';
var CryptoJS = require("crypto-js");



export const urlCode = (url) => {
  const merge = env.secretKey + url;
  var rawStr = CryptoJS.enc.Utf8.parse(merge)
  var base64 = CryptoJS.enc.Base64.stringify(rawStr);
  return CryptoJS.MD5(base64);
}

// Service No token 
// const headerAuthSecret = {
//   'Authorization': env.auth
// }

// Service No token 
// const headerAuthToken = {
//   'Authorization': getUserParameterCk(Constant.paramsKey.token)
// }

const defaultHeader = {
  'Content-type': 'application/json; charset=UTF-8'
}

// const HeaderAuthPublic = {...defaultHeader, ...headerAuthSecret}
const HeaderAuthPublic = { ...defaultHeader, }
// const HeaderAuthLogin = {...defaultHeader, ...headerAuthToken}

export  function getDevice() {
  let bodyDevice = {}
  let brand = { brand: 'Not detect' };
  let platform = { platform: "Not Detect" };
 
  try {
    if (navigator) {
      if (navigator.userAgentData && navigator.userAgentData.brands) {
        if (navigator.vendor) {
          brand = navigator.vendor;
        }
        if (navigator.userAgentData.brands[0] && navigator.userAgentData.brands[0]['brand'] !== 'Not:A-Brand') {
          brand = navigator.userAgentData.brands[0]['brand'];
        }
        if (navigator.userAgentData.brands[1] && navigator.userAgentData.brands[1]['brand'] !== 'Not:A-Brand') {
          brand = navigator.userAgentData.brands[1];
        }
        if (navigator.userAgentData.brands[2] && navigator.userAgentData.brands[2]['brand'] !== 'Not:A-Brand') {
          brand = navigator.userAgentData.brands[2];
        }
      }

      if (navigator.userAgentData && navigator.userAgentData.platform) {
        if (navigator.platform) {
          platform = { platform: navigator.platform };
        }
        if (navigator.userAgentData.platform) {
          platform = { platform: navigator.userAgentData.platform };
        }

      }
    }
  } catch (e) {
    brand = { brand: 'Not detect' };
    platform = { platform: "Not Detect" };
  }

  let ipAdd = {
    IPv4:  '0.0.0.0',
    longitude:'0,0',
    latitude: '0,0',
    country_code: 'Not Detect',
    country_name: 'Not Detect',
    state:  'Not Detect',
    loc:  'Not Detect',
    org: 'Not Detect',

  }
  // console.log(getUserParameterCk(Constant.paramsKey.location))
  
  let dataIP = false;
  if (!getUserParameterCk(Constant.paramsKey.location)) {
    const resdataIP =  getHttpGlobal('https://api.ipify.org?format=json');
    if (resdataIP?.data?.ip) {
      dataIP =  getHttpGlobal(`https://ipinfo.io/${resdataIP?.data?.ip}?token=38009a2e6e539f`);

      ipAdd = {
        IPv4: dataIP?.data ? dataIP?.data?.ip : '0.0.0.0',
        longitude:dataIP?.data?.loc ? dataIP?.data?.loc : '0,0',
        latitude: dataIP?.data?.loc ? dataIP?.data?.loc : '0,0',
        country_code: dataIP?.data?.country ? dataIP?.data?.country : 'Not Detect',
        country_name: dataIP?.data?.timezone ? dataIP?.data?.timezone : 'Not Detect',
        state: dataIP?.data?.region ? dataIP?.data?.region : 'Not Detect',
        loc: dataIP?.data?.loc ? dataIP?.data?.loc : 'Not Detect',
        org: dataIP?.data?.org ? dataIP?.data?.org : 'Not Detect',
    
      }
    
    }
   
  }

  bodyDevice = { brand, ...platform }


  let currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 5); // 5 jam save 
 

  if (dataIP?.data) {
    if (!getUserParameterCk(Constant.paramsKey.location)) {
      setUserParameterCk(Constant.paramsKey.location,ipAdd,currentDate)
    }
   
  }


  return {
    device: JSON.stringify(bodyDevice),
    ip: getUserParameterCk(Constant.paramsKey.location) ? getUserParameterCk(Constant.paramsKey.location) : ipAdd
  };

}
function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
}

export async function getHttpUrl(url = '', param = {}, headerAuth = false, token = false) {
  let mergeHeader = {};
  let ParamObject = param === '' || param === null || param === '{}' || param === undefined || param === false;
  if (headerAuth && token) {
    const headerAuthToken = {
      'Authorization': token
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else if (headerAuth && !token) {
    const headerAuthToken = {
      'Authorization': dataApi()
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else {
    mergeHeader = { ...HeaderAuthPublic }
  }


  return fetch('https://app.kapiten.id/' + url + (ParamObject ? '' : ('?' + objToQueryString(param))),
    { mode: 'cors', method: 'GET', headers: mergeHeader, }).then((response) => response.json()).catch((err) => {
      console.log(err.message);
      // sentrySEND(`[GET] - https://app.kapiten.id/${url}`, err, (ParamObject ? '' : ('?' + objToQueryString(param))))
    });
};


export async function getHttp(url = '', param = {}, headerAuth = false, token = false) {
  let mergeHeader = {};
  let ParamObject = param === '' || param === null || param === '{}' || param === undefined || param === false;
  if (headerAuth && token) {
    const headerAuthToken = {
      'Authorization': token
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else if (headerAuth && !token) {
    const headerAuthToken = {
      'Authorization': dataApi()
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else {
    mergeHeader = { ...HeaderAuthPublic }
  }
  
  // console.log(env.apiURL + url + (ParamObject ? '' : ('?' + objToQueryString(param))), 'GET ')
  // console.log(mergeHeader, 'mergeHeader')
  return fetch(env.apiURL + url + (ParamObject ? '' : ('?' + objToQueryString(param))),
    { mode: 'cors', method: 'GET', headers: mergeHeader, }).then((response) => response.json()).catch((err) => {
      console.log(err.message);
      // sentrySEND( '[GET] - ' + env.apiURL + url, err,  (ParamObject ? '' : ('?' + objToQueryString(param))))
    });
};

export const postHttp = (url = '', body = {}, headerAuth = false, token = false) => {
  let deviceArray = getDevice();

  let mergeHeader = {}
  if (headerAuth && token) {
    const headerAuthToken = {
      'Authorization': token
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else if (headerAuth && !token) {
    const headerAuthToken = {
      'Authorization': dataApi()
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else {
    mergeHeader = { ...HeaderAuthPublic }
  }
  let mergeBody = {
    ...body, ...deviceArray
  }

  return fetch(env.apiURL + url, { mode: 'cors', method: 'POST', headers: mergeHeader, body: JSON.stringify(mergeBody) }).then((response) => response.json()).catch((err) => {
    console.log(err);
    // sentrySEND('[POST] - ' + env.apiURL + url, err, JSON.stringify(mergeBody))
  });
};

export const postHttpRequest = (url = '', body, headerAuth = false, token = false) => {
  let deviceArray = getDevice();
  if (deviceArray) {
    body.append("device", deviceArray.device);
    body.append("ip", JSON.stringify(deviceArray.ip));
  }

  let mergeHeader = {}
  if (headerAuth && token) {
    const headerAuthToken = {
      'Authorization': token
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else if (headerAuth && !token) {
    const headerAuthToken = {
      'Authorization': dataApi()
    }

    mergeHeader = { ...headerAuthToken }
  } else {
    mergeHeader = { ...HeaderAuthPublic }
  }

  return fetch(env.apiURL + url, {
    method: 'POST',
    mode: 'cors',
    headers: mergeHeader,
    body: body
  }).then((response) => response.json()).catch((err) => {
    console.log(err.message);
    // sentrySEND('[POST] - ' +env.apiURL + url, err, body)
  });
};

export const putHttp = (url = '', body = {}, headerAuth = false, token = false) => {
  let deviceArray = getDevice();
  let mergeHeader = {}
  if (headerAuth && token) {
    const headerAuthToken = {
      'Authorization': token
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else if (headerAuth && !token) {
    const headerAuthToken = {
      'Authorization': dataApi()
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  } else {
    mergeHeader = { ...HeaderAuthPublic }
  }

  let mergeBody = {
    ...body, ...deviceArray
  }

  return fetch(env.apiURL + url, { mode: 'cors', method: 'PUT', headers: mergeHeader, body: JSON.stringify(mergeBody) }).then((response) => response.json()).catch((err) => {
    console.log(err.message);
    // sentrySEND('[PUT] - ' +env.apiURL + url, err, JSON.stringify(mergeBody) )
  });
};

export const delHttp = (url = '', body = {}, headerAuth = false) => {
  let deviceArray = getDevice();
  let mergeHeader = { ...HeaderAuthPublic }
  if (headerAuth) {
    const headerAuthToken = {
      'Authorization': dataApi()
    }
    mergeHeader = { ...defaultHeader, ...headerAuthToken }
  }

  let mergeBody = {
    ...body, ...deviceArray
  }

  return fetch(env.apiURL + url, { mode: 'cors', method: 'DELETE', headers: mergeHeader, body: JSON.stringify(mergeBody) }).then((response) => response.json()).catch((err) => {
    console.log(err.message);
    // sentrySEND('[DELETE] - ' +env.apiURL + url, err, JSON.stringify(mergeBody) )
  });
};

export async function getHttpGlobal(baseURL = '') {
  return axios.get(baseURL).then((response) => response).catch((err) => {
    console.log(err.message);
  });
};



import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import languageV from '../libs/lang/index';

function loadLanguage(locale) {
  return languageV(locale);
}

function mapStateToProps(state) {
  const messages = loadLanguage(state.locale);
  

  return { locale: state.locale, messages };
}

export default connect(mapStateToProps)(IntlProvider);

import env from './../../environment';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getToken } from "firebase/messaging";
import { removeUserParameter } from './index';
import Constant from '../../store/constant';
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: env.conFirestore.apiKey,
  authDomain: env.conFirestore.authDomain,
  databaseURL: env.conFirestore.databaseURL,
  projectId: env.conFirestore.projectId,
  storageBucket: env.conFirestore.storageBucket,
  messagingSenderId: env.conFirestore.messagingSenderId,
  appId: env.conFirestore.appId,
};

const firebaseApp = initializeApp(firebaseConfig);
export const dbConnect = getFirestore(firebaseApp);

export const dbMessaging = getMessaging(firebaseApp);

export const fetchToken = () => {
  return getToken(dbMessaging, { vapidKey: env.conFirestore.keyMsg }).then((currentToken) => {
    if (currentToken) {
      // setUserParameter(Constant.paramsKey.tokenNotif, currentToken);
      // console.log('current token for client: ', currentToken);
      return currentToken;
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      removeUserParameter(Constant.paramsKey.tokenNotif);
      console.log('No registration token available. Request permission to generate one.');
      return false;
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    return false;
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(dbMessaging, (payload) => {
      resolve(payload);
    });
});

  
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_NOTIF = 'COLLAPSE_NOTIF';
export const COLLAPSE_HELP = 'COLLAPSE_HELP';
export const ACCORDION_HELP = 'ACCORDION_HELP';
export const ACCORDION_HELP_DETAIL = 'ACCORDION_HELP_DETAIL';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const INNER_WIDTH_HEIGHT = 'INNER_WIDTH_HEIGHT';
export const USER_ACCESS = 'USER_ACCESS';
export const USER_PERMIT = 'USER_PERMIT';
export const DATAACCESS = 'DATAACCESS';
export const ACCESSMENU = 'ACCESSMENU';
export const INTLANG = 'INTLANG';
export const SET_LOCALE = 'SET_LOCALE';
export const TRX_FILTER_STATUS = 'TRX_FILTER_STATUS';
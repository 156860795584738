import 'react-app-polyfill/ie9'; // internet Explorer 9
import 'react-app-polyfill/stable'; // For other polyfills

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App/index';
import ConnectedIntlProvider from './App/helpers/ConnectedIntlProvider';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';

const store = createStore(reducer);



const app = (
        <Provider store={store}>
            <ConnectedIntlProvider>
                <BrowserRouter basename={config.basename}>
                    <App />
                </BrowserRouter>
            </ConnectedIntlProvider>
        </Provider>
);
const root = createRoot(document.getElementById('root'));

root.render(app);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import $ from 'jquery';
import Constant from "./store/constant";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
// const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));

const SignUp = React.lazy(() => import('./Page/Auth/Register/SignUp'));
const Register = React.lazy(() => import('./Page/Auth/Register/Register'));
// const SignIn = React.lazy(() => import('./Page/Auth/Login/SignIn'));
const SignInNew = React.lazy(() => import('./Page/Auth/Login/SignInNew'));
const BiddingPublic = React.lazy(() => import('./Page/Auth/Bidding/biddingPublish'));

const calculatorDistance = React.lazy(() => import('./Page/Auth/CalculatorMiles/CalculatorDistance'));
const ResetPass = React.lazy(() => import('./Page/Auth/ResetPass/ResetPass'));
const VerifyResetPass = React.lazy(() => import('./Page/Auth/ResetPass/VerifyResetPass'));
const VerifEmail = React.lazy(() => import('./Page/Auth/Other/VerifEmail'));
const TermCondition = React.lazy(() => import('./Page/General/TermCondition/TermAndCondition'));
const NotSupport = React.lazy(() => import('./Page/General/NotSupport'));

const route = [
    // { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/signup', exact: true, name: 'Register', component: Register },
    { path: '/register', exact: true, name: 'SignUp', component: SignUp },
    // { path: '/signin', exact: true, name: 'Login', component: SignIn },
    { path: '/signin', exact: true, name: 'Login', component: SignInNew },
    { path: '/home/bidding', exact: true, name: 'Login Apps', component: BiddingPublic },
    { path: '/home/dashboard', exact: true, name: 'Login Apps', component: SignInNew },
    { path: '/home/management-user', exact: true, name: 'Login Apps', component: SignInNew },
    { path: '/home/setting', exact: true, name: 'Login Apps', component: SignInNew },
    
    // { path: '/login', exact: true, name: 'Login', component: SignIn },
    { path: Constant.routeName.general[4], exact: false, name: 'Calculator maps', component: calculatorDistance },
    // { path: '/register', exact: true, name: 'Register', component: SignUp },
    { path: Constant.routeName.verification, exact: true, name: 'Verification Email', component: VerifEmail },
    { path: Constant.routeName.general[0], exact: false, name: 'Term And Condition', component: TermCondition },
    { path: Constant.routeName.general[1], exact: true, name: 'Reset Password', component: ResetPass },
    { path: Constant.routeName.resetNewPassword, exact: true, name: 'Reset Password', component: VerifyResetPass },
    { path: Constant.routeName.general[2], exact: false, name: 'Not Support', component: NotSupport },
];

export default route;
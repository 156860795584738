

import React, { Suspense } from 'react'; //Component
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { NavLink, withRouter } from 'react-router-dom';
// import {Toast} from 'react-bootstrap';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import { connect } from 'react-redux';
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import * as actionTypes from './../store/actions';
import routes from "../route";
import {
    setUserParameterCk,
    getUserParameterCk,
    removeUserParameterCk,
    setUserParameter,
    getUserParameter,
    removeUserParameter,
    encryptData
} from './helpers/index';
import Constant from './../store/constant';
import env from './../environment';
// import ReactHtmlParser from 'react-html-parser';
import {
    // fetchToken,
    onMessageListener
} from './../App/helpers/configFirebase';
import { getHttp, urlCode } from './../App/helpers/http';
import { Logout } from './../App/helpers/authentication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function merge(a, b, prop) {
    var reduced = a.filter(function (aitem) {
        return !b.find(function (bitem) {
            return aitem[prop] === bitem[prop];
        });
    });
    return reduced.concat(b);
}

class App extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.isNotification = { title: '', body: '' };
        this.isShow = false;
        this.token = false;

        this.showNotification = this.showNotification.bind(this);
        this.state = {
            isShow: false,
            granted: false
        }

    }


    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        document.body.style.overflowX = 'hidden';

        this._isMounted = true;
        if (this._isMounted) {
            if (this.getData3()) {
                if (this.getData2()) {
                    this.getData();
                }
            }
            toast.configure()
            if (!("Notification" in window)) {
                this.setState({
                    granted: false
                })
            } else {
                this.permission()
            }


        }
        return () => {
            document.body.style.overflowX = 'auto';
        };
    }
    permission = async () => {
        if (Notification.permission === 'granted') {
            this.setState({
                granted: true
            })
        } else if (Notification.permission !== 'denied') {
            let permission = await Notification.requestPermission();
            this.setState({
                granted: permission === 'granted' ? true : false
            })
        }
    }

    checkMssg = () => {
        onMessageListener().then(payload => {
            this.isNotification = { title: payload.notification.title, body: JSON.parse(payload.notification.body), image: payload.notification.image };
            this.isShow = false;
            if (payload.notification) {
                this.showNotification(this.isNotification)
                this.setState({
                    isShow: true,
                })
            }
        }).catch(err => {
            console.log('failed: ', err)
        });

    }
    showNotification(notif) {
        var options = {
            body: notif.body.desc,
            icon: notif.image,
            dir: 'ltr',
            vibrate: true
        };
        if (this.state.granted) {
            const CustomToastWithLink = () => (
                <div className=''>

                    {notif.title}<br></br>
                    <NavLink to={notif.body.link}>
                        {options.body}</NavLink>
                </div>
            );
            toast.info(CustomToastWithLink, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 100000,
                link: '',
            })
            setTimeout(() => {
                var notifopen = new Notification(notif.title, options)
                notifopen.addEventListener('click', () => {
                    window.open(notif.body.link, '_blank');
                });

                setTimeout(() => notifopen.close(), 10 * 1000);
            }, 1000);

        }



    }

    getDataDefaultManagementUser = async (datanya) => {
        if (getUserParameterCk(Constant.paramsKey.token)) {
            return await getHttp('get-user-management-default', '', true, getUserParameterCk(Constant.paramsKey.token))
                .then((actualData) => {
                    if (actualData['code'] === 200) {
                        return merge(actualData['message'], datanya, 'id');
                    } else {
                        return [];
                    }
                }).catch((e) => {
                    return [];
                });
        }
        return [];
    }
    getData3 = async () => {
        let headerAuth = getUserParameterCk(Constant.paramsKey.token) ? true : false;
        let menuItems = {
            "items": [
                {},
            ]

        }
        // console.log(getUserParameterCk(Constant.paramsKey.token), 'kesini')
        if ((headerAuth && !getUserParameter(Constant.paramsKey.menu_item)) || !getUserParameter(Constant.paramsKey.UserPermit)) { //

            if (getUserParameterCk(Constant.paramsKey.token)) {
                await getHttp('get-user-management', '', true, getUserParameterCk(Constant.paramsKey.token))
                    .then((resp) => {
                        if (resp['code'] === 200) {
                            // user Permission access
                            var ciphertext = encryptData(resp['message'])
                            setUserParameter(Constant.paramsKey.UserPermit, ciphertext);

                            let data = false;
                            let mergeData = this.getDataDefaultManagementUser(resp['message']);

                            mergeData.then((resp1) => {
                                data = resp1;
                                const main = Object.values(data.reduce((acc, { main_id, main_name }) => {
                                    let res = ((acc[main_id] = acc[main_id] || { main_id, main_name: 'MAIN' }).main_name = main_name, acc);
                                    return res
                                }, {}));
                                // let dataMain = [];

                                let MainMap = main;
                                main.map((d, i) => {
                                    MainMap[i].children = [];
                                    MainMap[i].title = main[i].main_name;
                                    MainMap[i].id = main[i].main_name + '_' + main[i].main_id;
                                    MainMap[i].type = 'group';
                                    data.map((d1, i1) => {

                                        if (data[i1].main_id === MainMap[i].main_id) {
                                            data[i1].title = data[i1].access_name;
                                            data[i1].type = 'item';
                                            data[i1].breadcrumbs = false;
                                            MainMap[i].children.push(data[i1]);
                                        }

                                        return true;
                                    });
                                    removeUserParameter(Constant.paramsKey.menu_item);
                                    setUserParameter(Constant.paramsKey.menu_item, JSON.stringify({ items: MainMap }));
                                    this.props.onChangeAccessMenu(JSON.stringify({ items: MainMap }));
                                    return true;
                                });
                                // data = resp1;
                            });
                            return true;
                        } else if (resp['code'] === 1005 || resp['code'] === 1004) {
                            Logout();
                            return false;
                        } else {
                            removeUserParameter(Constant.paramsKey.menu_item)
                            setUserParameter(Constant.paramsKey.menu_item, JSON.stringify(menuItems));
                            this.props.onChangeAccessMenu(JSON.stringify(menuItems));
                            return false;

                        }

                    }).catch((e) => {
                        return false;
                    });
            } else {
                return true;
            }

        } else {
            this.props.onChangeAccessMenu(getUserParameter(Constant.paramsKey.menu_item));
            return true;
        }
    }
    getData2 = async () => {
        let headerAuth = getUserParameterCk(Constant.paramsKey.token) ? true : false;
        let dataAccess = {
            approve: false,
            company_id: 0,
            company_name: null,
            created_at: null,
            name_role: '',
            nickname: null,
            role_id: 0,
            role_map_id: 0,
            updated_at: null,
            users_approve_id: 0,
            telp: 0,
            region_id: 0
        }
        if (headerAuth && !getUserParameter(Constant.paramsKey.userAccess)) {
            if (getUserParameterCk(Constant.paramsKey.token)) {
                return await getHttp('get-user-id-map', '', true, getUserParameterCk(Constant.paramsKey.token))
                    .then((data) => {
                        if (data['code'] === 200) {
                            removeUserParameter(Constant.paramsKey.userAccess);
                            setUserParameter(Constant.paramsKey.userAccess, JSON.stringify(data['message']));
                            this.props.onChangeUserAccess(getUserParameter(Constant.paramsKey.userAccess))
                            return true;
                        } else if (data['code'] === 1005 || data['code'] === 1004) {
                            Logout();
                            return false;
                        } else {
                            removeUserParameter(Constant.paramsKey.userAccess)
                            setUserParameter(Constant.paramsKey.userAccess, JSON.stringify(dataAccess));
                            // this.props.onChangeUserAccess(getUserParameter(Constant.paramsKey.userAccess));
                            return false;
                        }
                    }
                    ).catch(() => {
                        return true;
                    });
            } else {
                return true;
            }
        } else {
            this.props.onChangeUserAccess(getUserParameter(Constant.paramsKey.userAccess))

            return true;
        }
    }
    getData = async () => {
        let headerAuth = getUserParameterCk(Constant.paramsKey.token) ? true : false;
        let urlAuth = getUserParameterCk(Constant.paramsKey.token) ? urlCode('auth') : '';

        return await getHttp(urlAuth, '', headerAuth, headerAuth ? getUserParameterCk(Constant.paramsKey.token) : false)
            .then((data) => {
                if (data['code'] === 200) {
                    this.props.onChangeUserData(JSON.stringify(data['message']))


                    if (getUserParameterCk(Constant.paramsKey.version) === undefined ||
                        getUserParameterCk(Constant.paramsKey.version) === '' ||
                        getUserParameterCk(Constant.paramsKey.version) === null) {
                        removeUserParameterCk(Constant.paramsKey.version);
                        return true;
                    }
                    if (getUserParameterCk(Constant.paramsKey.version) !== data['message']['web_version']) {
                        removeUserParameterCk(Constant.paramsKey.version);
                        setUserParameterCk(Constant.paramsKey.version, data['message']['web_version']);
                        if (getUserParameterCk(Constant.paramsKey.version)) {
                            setTimeout(() => {
                                window.location.reload(false);
                            }, 1000);
                        }

                        return true;
                    }
                    return true;

                } else if (data['code'] === 1005 || data['code'] === 1004) {
                    if (headerAuth) {
                        Logout();
                    }
                    return true;
                } else {
                    removeUserParameterCk(Constant.paramsKey.version);
                    setUserParameterCk(Constant.paramsKey.version, env.version);
                    return true;
                }
            }
            ).catch((e) => {
                removeUserParameterCk(Constant.paramsKey.version);
                setUserParameterCk(Constant.paramsKey.version, env.version);
                return false;
            });

    }

    render() {
        this.checkMssg();

        console.log('%c', 'color: red; font-size: 100px');
        console.log('%cStop!', 'color: red; font-size: 40px; font-style: bold; text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;');
        console.log('%cThis is a browser feature aimed at developers. If someone asks you to copy something here to activate the website portal feature, this is a fraud and will give them access to your portal account.', 'color: #0e385f; font-size: 15px');
        console.log('%c', 'color: red; font-size: 100px');

        // if (this.getData3()) {
        //     if (this.getData2()) {
        //         this.getData();
        //     }
        // }

        const menu = routes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={AdminLayout2} 
                  />
            ) : (null);
        });
        return (
            <Aux>
                <ToastContainer />
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {!getUserParameterCk(Constant.paramsKey.token) && menu}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}
const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});
const AdminLayout2 = Loadable({
    loader: () => import('./layout/AdminLayout2'),
    loading: Loader
});

const mapStateToProps = state => {
    return {
        dataAccess: state.dataAccess,
        userData: state.userData,
        userPermit: state.userPermit,
        layout: state.layout,
        accessMenu: state.accessMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeUserAccess: (userAccess) => dispatch({ type: actionTypes.USER_ACCESS, userAccess: userAccess }),
        onChangeUserData: (params) => dispatch({ type: actionTypes.DATAACCESS, dataAccess: params }),
        onChangeAccessMenu: (accessMenu) => dispatch({ type: actionTypes.ACCESSMENU, accessMenu: accessMenu }),
        onChangeUserPermit: (userPermit) => dispatch({ type: actionTypes.USER_PERMIT, userPermit: userPermit }),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import * as actionTypes from './actions';
import config from './../config';
import env from './../environment';

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    ...env,
    isFullScreen: false, // static can't change
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: action.collapseMenu
            };
        case actionTypes.COLLAPSE_NOTIF:
            return {
                ...state,
                collapseNotif: action.collapseNotif,
            };
        case actionTypes.COLLAPSE_HELP:
            return {
                ...state,
                collapseHelp: action.collapseHelp,
            };
        case actionTypes.ACCORDION_HELP:
            return {
                ...state,
                accordionHelp: action.accordionHelp,
            };
        case actionTypes.ACCORDION_HELP_DETAIL:
            return {
                ...state,
                accordionHelpDetail: action.accordionHelpDetail,
            };

        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return { ...state };
        case actionTypes.FULL_SCREEN:
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.INNER_WIDTH_HEIGHT:
            return {
                ...state,
                innerHeight: window.innerHeight,
                innerWidth: window.innerWidth,
            };
        case actionTypes.USER_ACCESS:
            return {
                ...state,
                userAccess: action.userAccess,
            };
        case actionTypes.USER_PERMIT:
            return {
                ...state,
                userPermit: action.userPermit,
            };
        case actionTypes.DATAACCESS:
            return {
                ...state,
                dataAccess: action.dataAccess,
            };
        case actionTypes.ACCESSMENU:
            return {
                ...state,
                accessMenu: action.accessMenu,
            };
        case actionTypes.INTLANG:
            return {
                ...state,
                intlang: action.intlang,
            };
        case actionTypes.SET_LOCALE:
            return {
                ...state,
                state: action.locale,
            };
        case actionTypes.TRX_FILTER_STATUS:
            return {
                ...state,
                trx_filter_status: action.trx_filter_status,
            };
        default:
            return state;
    }
};

export default reducer;
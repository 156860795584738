import React from 'react';
import { withRouter } from 'react-router';
import { Auth } from './../../helpers/authentication';
import { getHttpGlobal } from './../../helpers/http';
import { setUserParameterCk, getUserParameterCk } from '../../helpers/index';
import Constant from '../../../store/constant';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }


    getLocation = async () => {
        let ipAdd = {
            IPv4: '0.0.0.0',
            longitude: '0.0',
            latitude: '0.0',
            country_code: 'Not Detect',
            country_name: 'Not Detect',
            state: 'Not Found',
        }
        let minutes = 60000;
        const paramIpCK1 = getUserParameterCk(Constant.paramsKey.location);

        if (!paramIpCK1) {
            setUserParameterCk(Constant.paramsKey.location, JSON.stringify(ipAdd), new Date(Date.now() + (minutes * 60)))
        }
        const paramIpCK = getUserParameterCk(Constant.paramsKey.location);
        // await getHttpGlobal('http://ip-api.com/json/')
        // ipAdd = {
        //     IPv4: actualData['query'] ? actualData['query'] : '0.0.0.0',
        //     longitude: actualData['lon'] ? actualData['lon'] : '0.0',
        //     latitude: actualData['lat'] ? actualData['lat'] : '0.0',
        //     country_code: actualData['timezone'] ? actualData['timezone'] : 'Not Detect',
        //     country_name: actualData['country'] ? actualData['country'] : 'Not Detect',
        //     state: actualData['city'] ? actualData['city'] : 'Not Found',
        // }

        if (paramIpCK && paramIpCK['IPv4'] === '0.0.0.0') {
            await getHttpGlobal('https://api.ipify.org?format=json')
                .then((resp) => {
                    const actualData = resp['data'];
                    console.log(actualData)
                    ipAdd = {
                        IPv4: actualData.ip || '0.0.0.0',
                        longitude: actualData?.lon ? actualData?.lon : '0.0',
                        latitude: actualData['lat'] ? actualData['lat'] : '0.0',
                        country_code: actualData['timezone'] ? actualData['timezone'] : 'Not Detect',
                        country_name: actualData['country'] ? actualData['country'] : 'Not Detect',
                        state: actualData['city'] ? actualData['city'] : 'Not Found',
                    }
                    if (actualData['country_name']?.toUpperCase() === 'INDONESIA') {
                        setUserParameterCk(Constant.paramsKey.dataLang, { value: 'id', label: "Indonesia" });
                        setUserParameterCk('language', 'id');
                        window.location.reload(false);
                    }
                    setUserParameterCk(Constant.paramsKey.location, JSON.stringify(ipAdd), new Date(Date.now() + (minutes * 60))) // 1jam
                    return true;

                }).catch((e) => {
                    setUserParameterCk(Constant.paramsKey.location, JSON.stringify(ipAdd), new Date(Date.now() + (minutes * 60))) // 1jam
                    return true;
                });
        }

    }

    render() {
        this.getLocation();
        Auth(this.props);
        return this.props.children
    }
}

export default withRouter(ScrollToTop);
import Constant from '../../../store/constant';
import { getUserParameterCk } from './../../helpers/index';

const CN = require('./cn.json');
const EN = require('./en.json');
const ID = require('./id.json');
const dummyID = require('./dummyID.json');
const dummyEN = require('./dummyEN.json');
const configurator = require('./configurator.json');

function configuratorDummy(dummy, langParse) {
  const messages = { ...dummy, ...langParse };
  const data = {};

  Object.keys(configurator).forEach(key => {
    Object.keys(dummy).forEach(keyDummy => {
      if (keyDummy === key) {
        if (configurator[key]) {
          data[key] = dummy[key];
        }
      }
    });

    Object.keys(langParse).forEach(keylang => {
      if (keylang === key) {
        if (!configurator[key]) {
          data[key] = langParse[key];
        }
      }
    });
  });

  return { ...data, ...messages };
}

export default function language(locale) {
  const langCode = getUserParameterCk(Constant?.paramsKey?.language) ? getUserParameterCk(Constant.paramsKey.language): 'id';

  let messages;
  switch (langCode) {
    case 'cn':
      messages = configuratorDummy(dummyEN, CN);
      break;
    case 'id':
      messages = configuratorDummy(dummyID, ID);
      break;
    default:
      messages = configuratorDummy(dummyEN, EN);
      break;
  }
  const useEnglishAsFallback = true;
  if (useEnglishAsFallback) return { ...EN, ...messages };

  return messages;
}

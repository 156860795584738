import Constant from '../../store/constant';
import { getUserParameterCk, removeUserParameterCk, removeUserParameter, getUserParameter, setUserParameter } from './index';
import route from "../../route";
import routes from "../../routes";
import { postHttp, urlCode, getHttp } from './../../App/helpers/http';
import { decryptData } from './../../App/helpers/index';
import jwt_decode from "jwt-decode";
import moment from 'moment';

export function Auth(prop) {
  const { history } = prop;
  let DataNameGeneral = false;
  let dataUrl = dataMenu();
  let dataRedirect = false;
  getUserParameter(Constant.paramsKey.userAccess);
  getUserParameter(Constant.paramsKey.menu_item);

  // Auth route signin Signup 
  route.map((routeName2) => {
    if (routeName2.path === prop.location.pathname && getUserParameterCk(Constant.paramsKey.token)) {
      Constant.routeName.general.map((idx) => {
        if (routeName2.path === idx) {
          DataNameGeneral = routeName2.path === idx;
        }
        return null;
      });
      if (!DataNameGeneral) {
        history.push('/apps/home');
      }
    }
    return null;
  }
  );


  // Auth route home dashboard 
  routes.map((routeName2) => {
    
    if (routeName2.path === prop.location.pathname && !getUserParameterCk(Constant.paramsKey.token)) {

      if (routeName2.path === '/calculator-maps-distance') {
        // LOST
      } else {
        history.push('/signin');
      }
    } else if (routeName2.path === prop.location.pathname && getUserParameterCk(Constant.paramsKey.token)) {
      if (dataUrl.length > 0) {
        dataUrl.map((idx2) => {
          if (routeName2.path === idx2.url) {
            dataRedirect = routeName2.path === idx2.url;
          }
          return null;
        });
        if (!dataRedirect) {
          // ?? must be check in routes component 
          // history.push('/apps/home');
        }
        return null;
      }
    }
    return null;
  });

}

export function AuthAccessPermit() {

  try {
    if (getUserParameter(Constant.paramsKey.userAccess)) {
      let dataAccess = JSON.parse(getUserParameter(Constant.paramsKey.userAccess));
     
      if (dataAccess && dataAccess.company_id !== 0) {
        let expired = moment(dataAccess.expired_date).format('DD MMMM yyyy');
        const currentDate = new Date();

        // Menghitung selisih hari
        const timeDifference = new Date(expired).getTime() - currentDate.getTime();
        let daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        if (parseInt(daysDifference) < 0) {
          // HABIS
          return {access:0, date: expired};
        } else {
          // MASUK ADA
          return {access:1, date: expired};
        }
      } else {
        // BELUM REGISTER
        return {access:1, date: ''};
      }

    }
  } catch (e) {
    return {access:2, date: ''};
  }
}

export function AuthPermission(paramFunc = null, name_access = 'Dashboard') {
  // const { history } = prop;
  var data = decryptData(getUserParameter(Constant.paramsKey.UserPermit));
  // console.log(data)
  if (!data) {
    // Logout();
    return false;
  }
  // let showData = false;
  let dataFuture = [];
  data.map((item) => {

    if (item.future_array_id !== null && item.future_array_id !== '') { // UBAH DODO
      if (item.access_name === name_access) {
        return dataFuture = item.future_array_id.split(",")
      }
    }
    return [];

  });
  if (dataFuture.length > 0) {
    let showData = false;
    for (let i = 0; i < dataFuture.length; i++) {
      if (parseInt(dataFuture[i]) === paramFunc) {

        showData = true;
        return showData;
      }
    }
    return false;
  }
  return false;

}

export function AuthPermit(prop, paramFunc = null) {
  // const { history } = prop;
  var data = decryptData(getUserParameter(Constant.paramsKey.UserPermit));

  if (!data) {
    // Logout();
    return false;
  }
  let result = false;
  let dataFuture = '';
  data.map((item) => {
    if (prop.location.pathname === item.url) {
      if (item.future_array_id !== null || item.future_array_id !== '') {
        dataFuture = item.future_array_id.split(",");
      } else {
        dataFuture = [];
      }
      result = true;
      return result;
    }
    return false;
  });

  // page permission
  if (!result) {
    // history.push(Constant.routeName.access_denied + '?backUrl=' + Constant.routeName.home);
    return false;
  }

  // function permission
  if (result) {
    let showData = false;
    for (let i = 0; i < dataFuture.length; i++) {
      if (paramFunc !== null && paramFunc.toString() === dataFuture[i]) {
        showData = true;
        return showData;
      }
    }
    return false;
  }

  return false;


}

export function dataMenu() {
  const dataLoad = JSON.parse(getUserParameter(Constant.paramsKey.menu_item));
  const navigate = dataLoad === null ? { items: [] } : dataLoad;
  let dataMobile = { items: [] };
  if (navigate.items.length > 0) {
    navigate.items.map((item, index) => {
      if (item.children) {
        item.children.map((i, idx) => {
          if (i.main_id === 1) {
            dataMobile.items.push(i);
          }
          if (i.main_id === 3) {
            dataMobile.items.push(i);
          }
          return { items: [] };;
        })
      }
      return { items: [] };;

    });
  }
  return dataMobile.items;
}

export function accessDataRole(props) {
  try {
    if (getUserParameter(Constant.paramsKey.userAccess)) {
      let dataAccess = JSON.parse(getUserParameter(Constant.paramsKey.userAccess));
      if (dataAccess) {
        return dataAccess;
      } else {
        props.history.push(Constant.routeName.home);
        return false;
      }
    }
  } catch (e) {
    props.history.push(Constant.routeName.home);
    return false;
  }

}





export function AuthAccessChecking(props) {

  try {
    if (getUserParameter(Constant.paramsKey.userAccess)) {
      let dataAccess = JSON.parse(getUserParameter(Constant.paramsKey.userAccess));

      if (dataAccess.company_id === 0) {

        setTimeout(() => {
          props.history.push(Constant.routeName.profileSetting);
        }, 1000);
      }
    }
  } catch (e) {
  }
  return;
}

export function dataApi() {
  if (getUserParameterCk(Constant.paramsKey.token)) {
    return getUserParameterCk(Constant.paramsKey.token);
  } else {
    return false;
  }
}

export function loadDataAccess() {
  if (getUserParameterCk(Constant.paramsKey.dataAccess)) {
    return getUserParameterCk(Constant.paramsKey.token);
  } else {
    return false;
  }
}

export function data() {
  if (getUserParameterCk(Constant.paramsKey.token)) {
    const data = jwt_decode(getUserParameterCk(Constant.paramsKey.token));

    return data;
  }
  return {
    created_at: '',
    email: '',
    exp: null,
    nickname: '',
    redisid: null,
    user_id: null
  };
}

export async function Logout() {
  return await postHttp(urlCode('logout'), {}, true)
    .then(() => {
      removeUserParameter('cardshow');
      removeUserParameter('bidFleetPage');
      removeUserParameterCk(Constant.paramsKey.token);
      removeUserParameterCk(Constant.paramsKey.location);
      removeUserParameter(Constant.paramsKey.menu_item);
      removeUserParameter(Constant.paramsKey.userAccess);
      removeUserParameter(Constant.paramsKey.UserPermit);
      setTimeout(() => {
        window.top.location.href = '/signin';
      }, 500);
      return true;
    }
    ).catch(() => {
      
      removeUserParameter('cardshow');
      removeUserParameter('bidFleetPage');
      removeUserParameterCk(Constant.paramsKey.token);
      removeUserParameter(Constant.paramsKey.UserPermit);
      removeUserParameterCk(Constant.paramsKey.location);
      removeUserParameter(Constant.paramsKey.userAccess);
      removeUserParameter(Constant.paramsKey.menu_item);
      setTimeout(() => {
        window.top.location.href = '/signin';
      }, 500);
      return true;
    });


}

export async function getUserAccess() {
  let headerAuth = getUserParameterCk(Constant.paramsKey.token) ? true : false;
  let dataAccess = {
    approve: false,
    company_id: 0,
    company_name: null,
    created_at: null,
    name_role: '',
    nickname: null,
    role_id: 0,
    role_map_id: 0,
    updated_at: null,
    users_approve_id: 0,
    telp: 0,
    region_id: 0
  }
  if (headerAuth && !getUserParameter(Constant.paramsKey.userAccess)) {
    if (getUserParameterCk(Constant.paramsKey.token)) {
      return await getHttp('get-user-id-map', '', true, getUserParameterCk(Constant.paramsKey.token))
        .then((data) => {
          if (data['code'] === 200) {
            removeUserParameter(Constant.paramsKey.userAccess);
            setUserParameter(Constant.paramsKey.userAccess, JSON.stringify(data['message']));
            this.props.onChangeUserAccess(getUserParameter(Constant.paramsKey.userAccess))
            return true;
          } else if (data['code'] === 1005 || data['code'] === 1004) {
            Logout();
          } else {
            removeUserParameter(Constant.paramsKey.userAccess)
            setUserParameter(Constant.paramsKey.userAccess, JSON.stringify(dataAccess));
            this.props.onChangeUserAccess(getUserParameter(Constant.paramsKey.userAccess))
            return false;
          }
        }
        ).catch(() => {
          return true;
        });
    } else {
      return true;
    }
  } else {
    this.props.onChangeUserAccess(getUserParameter(Constant.paramsKey.userAccess))

    return true;
  }


}
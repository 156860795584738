import React from 'react';
import $ from 'jquery';
import Constant from "./store/constant";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));

// const UIBasicButton = React.lazy(() => import('./Demo/UIElements/Basic/Button'));
// const UIBasicBadges = React.lazy(() => import('./Demo/UIElements/Basic/Badges'));
// const UIBasicBreadcrumbPagination = React.lazy(() => import('./Demo/UIElements/Basic/BreadcrumbPagination'));

// const UIBasicCollapse = React.lazy(() => import('./Demo/UIElements/Basic/Collapse'));
// const UIBasicTabsPills = React.lazy(() => import('./Demo/UIElements/Basic/TabsPills'));
// const UIBasicBasicTypography = React.lazy(() => import('./Demo/UIElements/Basic/Typography'));

// const FormsElements = React.lazy(() => import('./Demo/Forms/FormsElements'));

// const BootstrapTable = React.lazy(() => import('./Demo/Tables/BootstrapTable'));

// const Nvd3Chart = React.lazy(() => import('./Demo/Charts/Nvd3Chart/index'));

// // const GoogleMap = React.lazy(() => import('./Demo/Maps/GoogleMap/index'));

// const OtherSamplePage = React.lazy(() => import('./Demo/Other/SamplePage'));
// const OtherDocs = React.lazy(() => import('./Demo/Other/Docs'));

const HomePage = React.lazy(() => import('./Page/Home/Home'));
const BidPage = React.lazy(() => import('./Page/Bidding/pageBidding'));
const BidDetailPage = React.lazy(() => import('./Page/Bidding/Product/detailBidding'));
const BidDetailFleetPage = React.lazy(() => import('./Page/Bidding/Fleet/detailBiddingFleet'));
const BidAddEditPage = React.lazy(() => import('./Page/Bidding/Product/formBidding'));
const Product = React.lazy(() => import('./Page/Product/listProduct'));
const Fleet = React.lazy(() => import('./Page/Fleet/HomeFleet'));
const FleetDetail = React.lazy(() => import('./Page/Fleet/DetailFleet'));
const TugBoatDetail = React.lazy(() => import('./Page/Fleet/DetailTugboat'));
const Profile = React.lazy(() => import('./Page/Profile/formProfile'));
const SettingProfile = React.lazy(() => import('./Page/Profile/settingProfile'));
const SettingCompany = React.lazy(() => import('./Page/Company/settingCompany'));
const PageTransactions = React.lazy(() => import('./Page/Transactions/listTransactions'));
const PageDetailTrans = React.lazy(() => import('./Page/Transactions/detailTransactions'));
const PageManagementUser = React.lazy(() => import('./Page/ManagementUser'));
const PageApprover = React.lazy(() => import('./Page/Approver'));
const Logout = React.lazy(() => import('./Page/Auth/Logout/SignOut'));
const TermCondition = React.lazy(() => import('./Page/General/TermCondition/TermAndCondition'));
const NotFound = React.lazy(() => import('./Page/General/NotFound'));
const NotSupport = React.lazy(() => import('./Page/General/NotSupport'));
const AccessDenied = React.lazy(() => import('./Page/General/AccessDenied'));
const calculatorDistance = React.lazy(() => import('./Page/Auth/CalculatorMiles/CalculatorDistance'));

const PagePaymentSetting = React.lazy(() => import('./Page/Payment/listPayment'));
const PagePaymentRequest = React.lazy(() => import('./Page/Payment/setupPayment'));
const PagebankSetting = React.lazy(() => import('./Page/Bank/index'));
const PageWhatsappSetting = React.lazy(() => import('./Page/whatsappNumber/index'));

const formBank = React.lazy(() => import('./Page/Bank/formBank'));

const AdminPayment = React.lazy(() => import('./Page/Admin/Payment/index'));
const AdminVerif = React.lazy(() => import('./Page/Admin/Verification/index'));
const AdminFeeVerif = React.lazy(() => import('./Page/Admin/VerificationFee/index'));

const Fm_Fleet = React.lazy(() => import('./Page/FleetManagement/Fleet/HomeFleet'));
const Fm_FleetDetail = React.lazy(() => import('./Page/FleetManagement/Fleet/DetailFleet'));
const Fm_TugBoatDetail = React.lazy(() => import('./Page/FleetManagement/Fleet/DetailTugboat'));
const Fm_Product = React.lazy(() => import('./Page/FleetManagement/Product/listProduct'));

const Fm_BidPage = React.lazy(() => import('./Page/FleetManagement/Bidding/pageBidding'));
const Fm_BidDetailPage = React.lazy(() => import('./Page/FleetManagement/Bidding/Product/detailBidding'));
const Fm_BidAddEditPage = React.lazy(() => import('./Page/FleetManagement/Bidding/Product/formBidding'));
const Fm_PageTransactions = React.lazy(() => import('./Page/FleetManagement/Transactions/listTransactions'));
const Fm_PageTransactionsReportHome = React.lazy(() => import('./Page/FleetManagement/Transactions/reportHomeTransactions'));
const Fm_PageTransactionsReportTimestamp = React.lazy(() => import('./Page/FleetManagement/Transactions/reportTimestampTransactions'));
const Fm_PageTransactionsReportFleetTrx = React.lazy(() => import('./Page/FleetManagement/Transactions/reportFleetWithTrxTransactions'));
const Fm_PageTransactionsReportFleet = React.lazy(() => import('./Page/FleetManagement/Transactions/reportFleetWithFleetTransactions'));

// const Fm_PageTransactionsReport1 = React.lazy(() => import('./Page/FleetManagement/Transactions/reportTransactions'));

const Fm_PageDetailTrans = React.lazy(() => import('./Page/FleetManagement/Transactions/detailTransactions'));

const routes = [
    { path: Constant.routeName.home, exact: true, name: 'Home', component: HomePage },
    { path: Constant.routeName.bidding, exact: true, name: 'Bidding Product', component: BidPage },
    { path: Constant.routeName.bidding_detail, exact: true, name: 'Detail Bidding', component: BidDetailPage },
    { path: Constant.routeName.bidding_detail_fleet, exact: true, name: 'Detail Bidding', component: BidDetailFleetPage },
    { path: Constant.routeName.bidding_edit, exact: true, name: 'Go back', component: BidAddEditPage },
    { path: Constant.routeName.product, exact: true, name: 'Product', component: Product },
    { path: Constant.routeName.fleet, exact: true, name: 'Fleet', component: Fleet },
    { path: Constant.routeName.fleet_detail, exact: true, name: 'Fleet', component: FleetDetail },
    { path: Constant.routeName.tugboat_detail, exact: true, name: 'Fleet', component: TugBoatDetail },
    
    { path: Constant.routeName.profile, exact: true, name: 'Profile', component: Profile },
    { path: Constant.routeName.profileSetting, exact: true, name: '', component: SettingProfile },
    { path: Constant.routeName.companySetting, exact: true, name: 'Setting Your Company', component: SettingCompany },
    { path: Constant.routeName.transactions, exact: true, name: 'Transaction', component: PageTransactions },
    { path: Constant.routeName.transDetail, exact: true, name: 'Transaction Detail', component: PageDetailTrans },
    { path: Constant.routeName.approver, exact: true, name: 'Approver User', component: PageApprover },
    { path: Constant.routeName.managementUser, exact: true, name: 'Management User', component: PageManagementUser },

    { path: Constant.routeName.paymentSetting, exact: true, name: 'Setting', component: PagePaymentSetting },
    { path: Constant.routeName.paymentRequest, exact: true, name: 'Subscribe', component: PagePaymentRequest },

    { path: Constant.routeName.bankSetting, exact: true, name: 'Setting ', component: PagebankSetting },
    { path: Constant.routeName.formBank, exact: true, name: 'Bank Setting', component: formBank },
    { path: Constant.routeName.whatsappNotif, exact: true, name: 'Setting ', component: PageWhatsappSetting },
    
    

    { path: Constant.routeName.admin_payment, exact: true, name: 'Payment Verification', component: AdminPayment },
    { path: Constant.routeName.admin_verification, exact: true, name: 'Verification leads', component: AdminVerif },
    { path: Constant.routeName.admin_fee_verif, exact: true, name: 'Verification leads', component: AdminFeeVerif },
    
    { path:Constant.routeName.logout, exact: false, name: 'Logout', component: Logout },

    { path:Constant.routeName.tnc, exact: false, name: 'Term And Condition', component: TermCondition },
    { path:Constant.routeName.not_found, exact: false, name: 'Not Found', component: NotFound },
    { path:Constant.routeName.not_support, exact: false, name: 'Not Support', component: NotSupport },
    { path:Constant.routeName.calculatorDistance, exact: true, name: 'Calculator maps', component: calculatorDistance },
    { path: Constant.routeName.general[4], exact: false, name: 'Calculator maps', component: calculatorDistance },
    { path:Constant.routeName.access_denied, exact: false, name: 'Access Denied', component: AccessDenied },
    
    // {'Fleet Management'}
    { path: Constant.routeName.fm_fleet, exact: true, name: 'Manage Fleet', component: Fm_Fleet },
    { path: Constant.routeName.fm_fleet_detail, exact: true, name: 'Back', component: Fm_FleetDetail },
    { path: Constant.routeName.fm_tugboat_detail, exact: true, name: 'Back', component: Fm_TugBoatDetail },

    { path: Constant.routeName.fm_transactions, exact: true, name: 'Transaction', component: Fm_PageTransactions },
    { path: Constant.routeName.fm_report, exact: true, name: 'Report', component:  Fm_PageTransactionsReportHome},
    { path: Constant.routeName.fm_report + '/timestamp', exact: true, name: 'Report', component:  Fm_PageTransactionsReportTimestamp},
    { path: Constant.routeName.fm_report + '/fleet', exact: true, name: 'Report', component:  Fm_PageTransactionsReportFleet},
    { path: Constant.routeName.fm_report + '/transaction', exact: true, name: 'Report', component:  Fm_PageTransactionsReportFleetTrx},
    // 
    // { path: Constant.routeName.fm_report, exact: true, name: 'Report', component: Fm_PageTransactionsReport }, Fm_PageTransactionsReportFleetTrx
    // { path: '/apps/fm_report/a', exact: true, name: 'Report1', component: Fm_PageTransactionsReport }, Fm_PageTransactionsReportFleet
   
    { path: Constant.routeName.fm_transDetail, exact: true, name: 'Back', component: Fm_PageDetailTrans },
    
    

    { path: Constant.routeName.fm_product, exact: true, name: 'Product', component: Fm_Product },

    { path: Constant.routeName.fm_bidding, exact: true, name: 'Bidding Product', component: Fm_BidPage },
    { path: Constant.routeName.fm_bidding_detail, exact: true, name: 'Detail Bidding', component: Fm_BidDetailPage },
    { path: Constant.routeName.fm_bidding_edit, exact: true, name: 'Go back', component: Fm_BidAddEditPage },
    

    // {'Stop Fleet Management'}
    
    // { path: '/dashboard', exact: true, name: 'Default', component: DashboardDefault },
    // { path: '/basic/button', exact: true, name: 'Basic Button', component: UIBasicButton },
    // { path: '/basic/badges', exact: true, name: 'Basic Badges', component: UIBasicBadges },
    // { path: '/basic/breadcrumb-paging', exact: true, name: 'Basic Breadcrumb Pagination', component: UIBasicBreadcrumbPagination },
    // { path: '/basic/collapse', exact: true, name: 'Basic Collapse', component: UIBasicCollapse },
    // { path: '/basic/tabs-pills', exact: true, name: 'Basic Tabs & Pills', component: UIBasicTabsPills },
    // { path: '/basic/typography', exact: true, name: 'Basic Typography', component: UIBasicBasicTypography },
    // { path: '/forms/form-basic', exact: true, name: 'Forms Elements', component: FormsElements },
    // { path: '/tables/bootstrap', exact: true, name: 'Bootstrap Table', component: BootstrapTable },
    // { path: '/charts/nvd3', exact: true, name: 'Nvd3 Chart', component: Nvd3Chart },
    // // { path: '/maps/google-map', exact: true, name: 'Google Map', component: GoogleMap },
    // { path: '/sample-page', exact: true, name: 'Sample Page', component: OtherSamplePage },
    // { path: '/docs', exact: true, name: 'Documentation', component: OtherDocs },
];

export default routes;
const Constant = {
    BLANK_LINK: "#!",
    routeName:  {
        home: '/apps/home',
        bidding: '/apps/bidding',
        bidding_detail: '/apps/bidding/detail',
        fm_bidding: '/apps/fm_bidding',
        fm_bidding_detail: '/apps/fm_bidding/detail',
        fm_report:'/apps/fm_report',
        bidding_detail_fleet: '/apps/bidding/detail/fleet',
        bidding_edit :'/apps/bidding/edit',
        fm_bidding_edit :'/apps/fm_bidding/edit',
        product: '/apps/product',
        fm_product: '/apps/fm_product',
        fleet: '/apps/fleet',
        fleet_detail: '/apps/fleet/detail',
        tugboat_detail: '/apps/fleet/tugboat',
        fm_fleet: '/apps/fm_fleet',
        fm_fleet_detail: '/apps/fm_fleet/detail',
        fm_tugboat_detail: '/apps/fm_fleet/tugboat',
        profile: '/apps/profile',
        routeMobile: '/apps/profile/route-mobile',
        profileSetting: '/apps/profile/setting',
        companySetting: '/apps/company/setting',
        paymentSetting: '/apps/payment/setting',
        paymentRequest: '/apps/payment/request',
        bankSetting: '/apps/bank/setting',
        formBank :'/apps/bank/form',
        transactions: '/apps/transactions',
        transDetail: '/apps/transactions/detail',
        fm_transactions: '/apps/fm_transactions',
        fm_transDetail: '/apps/fm_transactions/detail',
        approver: '/app/users/approver',
        managementUser: '/apps/users/management',
        admin_payment: '/apps/verification-payment',
        admin_verification: '/apps/verification-leads',
        admin_fee_verif: '/apps/verification-admin-fee',
        logout: '/apps/logout',
        verification: '/verification/email',
        resetNewPassword: '/reset/password',
        tnc: '/apps/term-and-conditions',
        not_found: '/apps/404',
        not_support: '/not-support',
        calculatorDistance: '/apps/calculator-maps-distance',
        access_denied: '/access-denied',
        login: '/signin',
        whatsappNotif: '/apps/profile/setting/number-whatsapp',
        general: [
         '/term-and-conditions',
         '/reset-password',
         'reset/password',
         '/not-support',
         '/calculator-maps-distance',
        ]
        
    },
    paramsKey:  {
      version: 'version',
      token: 'token_user',
      location: 'ip_location', 
      userAccess: 'user_access',
      cacheCreateProduct: 'cacheCreateProduct',
      menu_item: 'menu_item',
      UserPermit: 'user_permit',
      tokenNotif: 'noken_notif',
      language:'language',
      dataLang: 'dataLang'
  }, 
   itemBidding: 
   [
      {
         img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAvCAMAAADuOqUiAAAA0lBMVEX///8Aj4PrrBJ0dXjR0dJ/gIPp6eqFhogAjIDy8vLj4+OnqKp3eHuhoaT8/PzqqQBtbnH1rgDd3d6MjY+1trfx+fjq9PMAh3r99uad0Mvf8O68vb7R6efKysuSkpSy2tduvrfE4t4SnJFPr6f+/PYuoZf66cr33rH11ZbxxF/00Yn226j88tvxxGbtsyTxyHJqs6xIpJqKyMLuuEHwv07557/I4NTuuTNXm2/Dqi6qozwXkneWn03Mskh0m13YqRCNok/V49A8lHN/nVRnm1qxpSux69juAAADmUlEQVRYhcWYa3+aMBTGgYCABlC8ICKI117crNp1bXfr1q7f/yvtJAFFTHAr2D1vGmx++efkOecElaT/rG5vMPLjOPbHve77QQejeOJ5Bsjz5OlocAI96/dn5an20J8DUU5lGHN/IJ7eX1xcXl1dLxf9ctiev8lAE/LGF4T8Yflx5VCtbu7KBD1YyzkqJcvrIWfybHmlOEoiZ7UsAR7wsIS8GR3NvYNYlZ2c+8XbsZLt87myMdkezuxf32eowL0ogYVkjj0BWI6zJl/cKgdyLkth4aSngohlY91LJy0+Oc4h9qZkPkvSeCICexuSXbPZXZ6qOLdlzE3kC7AQ8fxzfwG+5qiKsipnLlN3LQY/PB5DKzCXabApAH9pHWNvKuiTRGMhV5a/flPy5n4vnVOpfEExUf14zIbcevr5qyqsJAmLiej55anFYnZaypcH366O2xUWEyP/flVardbT68uzF1d6Qw8LuXsZ84JL8i0SNeocdjKuFguN+q/AVZrLJG7UGVVrLtN4fhI8rdhcKlvcqBMdmuuqqpuOVVWtsUXgw9QKMzMmD4lqeXA3PmXuwfRA06J0URiyPag4HUlSva01zN3OdI0Ju1JeBY0aZMSHOeXqCKvJFhCy2D8jhFGQcnVk7bkaQk2iDiczh0XYST6nIqCxURtpIUMhjJFV43MjNQzVOrcgtuJG7R3llIqRXqcADekM0IBwcXoKea7FIyYSFpN3/IIpwUF3GA3Rv+Az0uAhcfVfuLagmPLmUoGtESxsthFmR9vBwExP4ZhrEgkaz5B7QxjTHmeuiaivIVjHNg2O100LNQObw8U0r7S6IGJeFRtz3vcHSbLgTG27gZpqGpNObY9MHheTMmoflW+i7paTyhxz6cpN1HbrkM00PtgAisKwA5G5HC7N51A1+UtxG/VW1JbbCAUBbrKscsFnOEtSSg2bwy3KK6KjRs01lypsogjY7OwCjGlnIOTaG7g5i0XmEpk6bRT0mGuQUGoN5EaQWQl3l73AbZzi5hq1L55pd5oQ5C6rIoYJMNIYV7caRCrLOTq2hAaTRp35ASAu2mIdI9SmK5mNtH6I0WQvwGUi/pP+zB5ECU20/9JkzIuwcO/pKLQZTEuaJSS2pln0PkoUZO+jgnj3FhuTU1e9mVSGDc7ubl4Ys0+YzOxD8ZtS+rolqNyzqccs3lb+HndKxOLMV+/300g2NuLKPZ/sragtn1mD8bube179ARA6QgytE2OmAAAAAElFTkSuQmCC',
         name: 'PT Vale Indonesia Tbk (IUP)',
         location: 'morowali',
         nego: true,
         ads: true,
         destination: 'PT IMIP',
         url_loction: 'http .....',
         price: '12K',
         unit: 'Ton',
         color:'orange',
         product: 'Nikel',
         amount_product: '300 ton'
      },
      {
         img: 'https://media.licdn.com/dms/image/C4D0BAQFckBObXi37eA/company-logo_200_200/0/1519952060656?e=1684972800&v=beta&t=1_ogqayZQOaA87y9zrQC2Qc2Fl4AKChcr9ZA2ZvJSkM',
         name: 'Pabrik Nikel PT.SMI',
         location: 'Fatufia, Bahodopi Mo...',
         nego: false,
         destination: 'PT Smelter Indo',
         url_loction: 'http .....',
         price: '13.3K',
         unit: 'Ton',
         color:'blue',
         product: 'Fosfat',
         amount_product: '1203 ton'
      },
      {
         img: 'https://www.apni.or.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.d7c519b4.png&w=256&q=75',
         name: 'PT. NIKEL NUSANTARA MANDIRI',
         nego: false,
         location: 'Sulawesi Tengah ...',
         destination: 'PT Shelter Jakarta',
         url_loction: 'http .....',
         price: '132K',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '300 ton'
      },
      {
         img: 'https://imip.co.id/assets/uploads/logo.png',
         name: 'PT IMIP',
         nego: true,
         location: 'Sulawesi Timur',
         destination: 'PT Indo Jaya Group',
         url_loction: 'http .....',
         price: '22K',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '993 ton'
      },
      {
         img: 'https://huadi.co.id/wp-content/uploads/2022/12/wadi-niki.png',
         name: 'PT HNI',
         nego: false,
         location: 'Bahodopi',
         destination: 'PT Batterai Indo',
         url_loction: 'http .....',
         price: '73K',
         unit: 'Ton',
         color:' red',
         product: 'Batu Bara',
         amount_product: '300 ton'
      },
   ],
   itemTransaction: 
   [
         {
            img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAvCAMAAADuOqUiAAAA0lBMVEX///8Aj4PrrBJ0dXjR0dJ/gIPp6eqFhogAjIDy8vLj4+OnqKp3eHuhoaT8/PzqqQBtbnH1rgDd3d6MjY+1trfx+fjq9PMAh3r99uad0Mvf8O68vb7R6efKysuSkpSy2tduvrfE4t4SnJFPr6f+/PYuoZf66cr33rH11ZbxxF/00Yn226j88tvxxGbtsyTxyHJqs6xIpJqKyMLuuEHwv07557/I4NTuuTNXm2/Dqi6qozwXkneWn03Mskh0m13YqRCNok/V49A8lHN/nVRnm1qxpSux69juAAADmUlEQVRYhcWYa3+aMBTGgYCABlC8ICKI117crNp1bXfr1q7f/yvtJAFFTHAr2D1vGmx++efkOecElaT/rG5vMPLjOPbHve77QQejeOJ5Bsjz5OlocAI96/dn5an20J8DUU5lGHN/IJ7eX1xcXl1dLxf9ctiev8lAE/LGF4T8Yflx5VCtbu7KBD1YyzkqJcvrIWfybHmlOEoiZ7UsAR7wsIS8GR3NvYNYlZ2c+8XbsZLt87myMdkezuxf32eowL0ogYVkjj0BWI6zJl/cKgdyLkth4aSngohlY91LJy0+Oc4h9qZkPkvSeCICexuSXbPZXZ6qOLdlzE3kC7AQ8fxzfwG+5qiKsipnLlN3LQY/PB5DKzCXabApAH9pHWNvKuiTRGMhV5a/flPy5n4vnVOpfEExUf14zIbcevr5qyqsJAmLiej55anFYnZaypcH366O2xUWEyP/flVardbT68uzF1d6Qw8LuXsZ84JL8i0SNeocdjKuFguN+q/AVZrLJG7UGVVrLtN4fhI8rdhcKlvcqBMdmuuqqpuOVVWtsUXgw9QKMzMmD4lqeXA3PmXuwfRA06J0URiyPag4HUlSva01zN3OdI0Ju1JeBY0aZMSHOeXqCKvJFhCy2D8jhFGQcnVk7bkaQk2iDiczh0XYST6nIqCxURtpIUMhjJFV43MjNQzVOrcgtuJG7R3llIqRXqcADekM0IBwcXoKea7FIyYSFpN3/IIpwUF3GA3Rv+Az0uAhcfVfuLagmPLmUoGtESxsthFmR9vBwExP4ZhrEgkaz5B7QxjTHmeuiaivIVjHNg2O100LNQObw8U0r7S6IGJeFRtz3vcHSbLgTG27gZpqGpNObY9MHheTMmoflW+i7paTyhxz6cpN1HbrkM00PtgAisKwA5G5HC7N51A1+UtxG/VW1JbbCAUBbrKscsFnOEtSSg2bwy3KK6KjRs01lypsogjY7OwCjGlnIOTaG7g5i0XmEpk6bRT0mGuQUGoN5EaQWQl3l73AbZzi5hq1L55pd5oQ5C6rIoYJMNIYV7caRCrLOTq2hAaTRp35ASAu2mIdI9SmK5mNtH6I0WQvwGUi/pP+zB5ECU20/9JkzIuwcO/pKLQZTEuaJSS2pln0PkoUZO+jgnj3FhuTU1e9mVSGDc7ubl4Ys0+YzOxD8ZtS+rolqNyzqccs3lb+HndKxOLMV+/300g2NuLKPZ/sragtn1mD8bube179ARA6QgytE2OmAAAAAElFTkSuQmCC',
            name: 'PT Vale Indonesia Tbk',
            noTrx: '24072023001',
            status: 'Success',
            statusColor: '#00b88a',
            bgColor: 'rgb(0 241 56 / 30%)',
            url_loction: 'http .....',
            offerPriceColor: '(Deal)',
            offerPrice: '6.000',
            nego: false,
            price: '9.000',
            unit: 'Ton',
            color:'orange',
            product: 'Nikel',
            amount_product: '300 ton'
         },
         {
            img: 'https://huadi.co.id/wp-content/uploads/2022/12/wadi-niki.png',
            name: 'PT HNI',
            noTrx: '24072023002',
            status: 'Proccess',
            statusColor: '#2e9097',
            bgColor: 'rgb(0 241 199 / 30%)',
            offerPriceColor: '(Deal)',
            offerPrice: '6.000',
            nego: false,
            url_loction: 'http .....',
            price: '8.000',
            unit: 'Ton',
            color:' red',
            product: 'Batu Bara',
            amount_product: '300 ton'
         },
         {
            img: 'https://media.licdn.com/dms/image/C4D0BAQFckBObXi37eA/company-logo_200_200/0/1519952060656?e=1684972800&v=beta&t=1_ogqayZQOaA87y9zrQC2Qc2Fl4AKChcr9ZA2ZvJSkM',
            name: 'Pabrik Nikel PT.SMI',
            noTrx: '24072023003',
            status: 'Failed',
            statusColor: '#f00',
            bgColor: 'rgb(243 105 105 / 16%)',
            offerPriceColor: '(failed)',
            offerPrice: '7.000',
            nego: true,
            url_loction: 'http .....',
            price: '8.000',
            unit: 'Ton',
            color:'blue',
            product: 'Fosfat',
            amount_product: '1203 ton'
         },
         {
            img: 'https://www.apni.or.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.d7c519b4.png&w=256&q=75',
            name: 'PT. NIKEL NUSANTARA MANDIRI',
            noTrx: '24072023004',
            status: 'Negotiation',
            statusColor: '#0052f1',
            bgColor: 'rgb(98 131 255 / 24%)',
            offerPriceColor: '(nego)',
            offerPrice: '8.000',
            nego: true,
            url_loction: 'http .....',
            price: '11.000',
            unit: 'Ton',
            color:'grey',
            product: 'Biji Besi',
            amount_product: '300 ton'
         },
         {
            img: 'https://imip.co.id/assets/uploads/logo.png',
            name: 'PT IMIP',
            noTrx: '24072023005',
            status: 'Shipped',
            statusColor: '#ff9100',
            bgColor: 'rgb(255 160 85 / 27%)',
            offerPriceColor: '(Deal)',
            offerPrice: '4.000',
            nego: false,
            url_loction: 'http .....',
            price: '14.000',
            unit: 'Ton',
            color:'grey',
            product: 'Biji Besi',
            amount_product: '993 ton'
         },
         {
         img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAvCAMAAADuOqUiAAAA0lBMVEX///8Aj4PrrBJ0dXjR0dJ/gIPp6eqFhogAjIDy8vLj4+OnqKp3eHuhoaT8/PzqqQBtbnH1rgDd3d6MjY+1trfx+fjq9PMAh3r99uad0Mvf8O68vb7R6efKysuSkpSy2tduvrfE4t4SnJFPr6f+/PYuoZf66cr33rH11ZbxxF/00Yn226j88tvxxGbtsyTxyHJqs6xIpJqKyMLuuEHwv07557/I4NTuuTNXm2/Dqi6qozwXkneWn03Mskh0m13YqRCNok/V49A8lHN/nVRnm1qxpSux69juAAADmUlEQVRYhcWYa3+aMBTGgYCABlC8ICKI117crNp1bXfr1q7f/yvtJAFFTHAr2D1vGmx++efkOecElaT/rG5vMPLjOPbHve77QQejeOJ5Bsjz5OlocAI96/dn5an20J8DUU5lGHN/IJ7eX1xcXl1dLxf9ctiev8lAE/LGF4T8Yflx5VCtbu7KBD1YyzkqJcvrIWfybHmlOEoiZ7UsAR7wsIS8GR3NvYNYlZ2c+8XbsZLt87myMdkezuxf32eowL0ogYVkjj0BWI6zJl/cKgdyLkth4aSngohlY91LJy0+Oc4h9qZkPkvSeCICexuSXbPZXZ6qOLdlzE3kC7AQ8fxzfwG+5qiKsipnLlN3LQY/PB5DKzCXabApAH9pHWNvKuiTRGMhV5a/flPy5n4vnVOpfEExUf14zIbcevr5qyqsJAmLiej55anFYnZaypcH366O2xUWEyP/flVardbT68uzF1d6Qw8LuXsZ84JL8i0SNeocdjKuFguN+q/AVZrLJG7UGVVrLtN4fhI8rdhcKlvcqBMdmuuqqpuOVVWtsUXgw9QKMzMmD4lqeXA3PmXuwfRA06J0URiyPag4HUlSva01zN3OdI0Ju1JeBY0aZMSHOeXqCKvJFhCy2D8jhFGQcnVk7bkaQk2iDiczh0XYST6nIqCxURtpIUMhjJFV43MjNQzVOrcgtuJG7R3llIqRXqcADekM0IBwcXoKea7FIyYSFpN3/IIpwUF3GA3Rv+Az0uAhcfVfuLagmPLmUoGtESxsthFmR9vBwExP4ZhrEgkaz5B7QxjTHmeuiaivIVjHNg2O100LNQObw8U0r7S6IGJeFRtz3vcHSbLgTG27gZpqGpNObY9MHheTMmoflW+i7paTyhxz6cpN1HbrkM00PtgAisKwA5G5HC7N51A1+UtxG/VW1JbbCAUBbrKscsFnOEtSSg2bwy3KK6KjRs01lypsogjY7OwCjGlnIOTaG7g5i0XmEpk6bRT0mGuQUGoN5EaQWQl3l73AbZzi5hq1L55pd5oQ5C6rIoYJMNIYV7caRCrLOTq2hAaTRp35ASAu2mIdI9SmK5mNtH6I0WQvwGUi/pP+zB5ECU20/9JkzIuwcO/pKLQZTEuaJSS2pln0PkoUZO+jgnj3FhuTU1e9mVSGDc7ubl4Ys0+YzOxD8ZtS+rolqNyzqccs3lb+HndKxOLMV+/300g2NuLKPZ/sragtn1mD8bube179ARA6QgytE2OmAAAAAElFTkSuQmCC',
         name: 'PT Vale Indonesia Tbk',
         noTrx: '24072023001',
         status: 'Success',
         statusColor: '#00b88a',
         bgColor: 'rgb(0 241 56 / 30%)',
         url_loction: 'http .....',
         offerPriceColor: '(Deal)',
         offerPrice: '6.000',
         nego: false,
         price: '9.000',
         unit: 'Ton',
         color:'orange',
         product: 'Nikel',
         amount_product: '300 ton'
      },
      {
            img: 'https://huadi.co.id/wp-content/uploads/2022/12/wadi-niki.png',
            name: 'PT HNI',
            noTrx: '24072023002',
            status: 'Proccess',
            statusColor: '#2e9097',
            bgColor: 'rgb(0 241 199 / 30%)',
            offerPriceColor: '(Deal)',
            offerPrice: '6.000',
            nego: false,
            url_loction: 'http .....',
            price: '8.000',
            unit: 'Ton',
            color:' red',
            product: 'Batu Bara',
            amount_product: '300 ton'
         },
      {
         img: 'https://media.licdn.com/dms/image/C4D0BAQFckBObXi37eA/company-logo_200_200/0/1519952060656?e=1684972800&v=beta&t=1_ogqayZQOaA87y9zrQC2Qc2Fl4AKChcr9ZA2ZvJSkM',
         name: 'Pabrik Nikel PT.SMI',
         noTrx: '24072023003',
         status: 'Failed',
         statusColor: '#f00',
         bgColor: 'rgb(243 105 105 / 16%)',
         offerPriceColor: '(failed)',
         offerPrice: '7.000',
         nego: true,
         url_loction: 'http .....',
         price: '8.000',
         unit: 'Ton',
         color:'blue',
         product: 'Fosfat',
         amount_product: '1203 ton'
      },
      {
         img: 'https://www.apni.or.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.d7c519b4.png&w=256&q=75',
         name: 'PT. NIKEL NUSANTARA MANDIRI',
         noTrx: '24072023004',
         status: 'Negotiation',
         statusColor: '#0052f1',
         bgColor: 'rgb(98 131 255 / 24%)',
         offerPriceColor: '(nego)',
         offerPrice: '8.000',
         nego: true,
         url_loction: 'http .....',
         price: '11.000',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '300 ton'
      },
      {
         img: 'https://imip.co.id/assets/uploads/logo.png',
         name: 'PT IMIP',
         noTrx: '24072023005',
         status: 'Shipped',
         statusColor: '#ff9100',
         bgColor: 'rgb(255 160 85 / 27%)',
         offerPriceColor: '(Deal)',
         offerPrice: '4.000',
         nego: false,
         url_loction: 'http .....',
         price: '14.000',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '993 ton'
      },
      {
         img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAvCAMAAADuOqUiAAAA0lBMVEX///8Aj4PrrBJ0dXjR0dJ/gIPp6eqFhogAjIDy8vLj4+OnqKp3eHuhoaT8/PzqqQBtbnH1rgDd3d6MjY+1trfx+fjq9PMAh3r99uad0Mvf8O68vb7R6efKysuSkpSy2tduvrfE4t4SnJFPr6f+/PYuoZf66cr33rH11ZbxxF/00Yn226j88tvxxGbtsyTxyHJqs6xIpJqKyMLuuEHwv07557/I4NTuuTNXm2/Dqi6qozwXkneWn03Mskh0m13YqRCNok/V49A8lHN/nVRnm1qxpSux69juAAADmUlEQVRYhcWYa3+aMBTGgYCABlC8ICKI117crNp1bXfr1q7f/yvtJAFFTHAr2D1vGmx++efkOecElaT/rG5vMPLjOPbHve77QQejeOJ5Bsjz5OlocAI96/dn5an20J8DUU5lGHN/IJ7eX1xcXl1dLxf9ctiev8lAE/LGF4T8Yflx5VCtbu7KBD1YyzkqJcvrIWfybHmlOEoiZ7UsAR7wsIS8GR3NvYNYlZ2c+8XbsZLt87myMdkezuxf32eowL0ogYVkjj0BWI6zJl/cKgdyLkth4aSngohlY91LJy0+Oc4h9qZkPkvSeCICexuSXbPZXZ6qOLdlzE3kC7AQ8fxzfwG+5qiKsipnLlN3LQY/PB5DKzCXabApAH9pHWNvKuiTRGMhV5a/flPy5n4vnVOpfEExUf14zIbcevr5qyqsJAmLiej55anFYnZaypcH366O2xUWEyP/flVardbT68uzF1d6Qw8LuXsZ84JL8i0SNeocdjKuFguN+q/AVZrLJG7UGVVrLtN4fhI8rdhcKlvcqBMdmuuqqpuOVVWtsUXgw9QKMzMmD4lqeXA3PmXuwfRA06J0URiyPag4HUlSva01zN3OdI0Ju1JeBY0aZMSHOeXqCKvJFhCy2D8jhFGQcnVk7bkaQk2iDiczh0XYST6nIqCxURtpIUMhjJFV43MjNQzVOrcgtuJG7R3llIqRXqcADekM0IBwcXoKea7FIyYSFpN3/IIpwUF3GA3Rv+Az0uAhcfVfuLagmPLmUoGtESxsthFmR9vBwExP4ZhrEgkaz5B7QxjTHmeuiaivIVjHNg2O100LNQObw8U0r7S6IGJeFRtz3vcHSbLgTG27gZpqGpNObY9MHheTMmoflW+i7paTyhxz6cpN1HbrkM00PtgAisKwA5G5HC7N51A1+UtxG/VW1JbbCAUBbrKscsFnOEtSSg2bwy3KK6KjRs01lypsogjY7OwCjGlnIOTaG7g5i0XmEpk6bRT0mGuQUGoN5EaQWQl3l73AbZzi5hq1L55pd5oQ5C6rIoYJMNIYV7caRCrLOTq2hAaTRp35ASAu2mIdI9SmK5mNtH6I0WQvwGUi/pP+zB5ECU20/9JkzIuwcO/pKLQZTEuaJSS2pln0PkoUZO+jgnj3FhuTU1e9mVSGDc7ubl4Ys0+YzOxD8ZtS+rolqNyzqccs3lb+HndKxOLMV+/300g2NuLKPZ/sragtn1mD8bube179ARA6QgytE2OmAAAAAElFTkSuQmCC',
         name: 'PT Vale Indonesia Tbk',
         noTrx: '24072023001',
         status: 'Success',
         statusColor: '#00b88a',
         bgColor: 'rgb(0 241 56 / 30%)',
         url_loction: 'http .....',
         offerPriceColor: '(Deal)',
         offerPrice: '6.000',
         nego: false,
         price: '9.000',
         unit: 'Ton',
         color:'orange',
         product: 'Nikel',
         amount_product: '300 ton'
      },
      {
            img: 'https://huadi.co.id/wp-content/uploads/2022/12/wadi-niki.png',
            name: 'PT HNI',
            noTrx: '24072023002',
            status: 'Proccess',
            statusColor: '#2e9097',
            bgColor: 'rgb(0 241 199 / 30%)',
            offerPriceColor: '(Deal)',
            offerPrice: '6.000',
            nego: false,
            url_loction: 'http .....',
            price: '8.000',
            unit: 'Ton',
            color:' red',
            product: 'Batu Bara',
            amount_product: '300 ton'
         },
      {
         img: 'https://media.licdn.com/dms/image/C4D0BAQFckBObXi37eA/company-logo_200_200/0/1519952060656?e=1684972800&v=beta&t=1_ogqayZQOaA87y9zrQC2Qc2Fl4AKChcr9ZA2ZvJSkM',
         name: 'Pabrik Nikel PT.SMI',
         noTrx: '24072023003',
         status: 'Failed',
         statusColor: '#f00',
         bgColor: 'rgb(243 105 105 / 16%)',
         offerPriceColor: '(failed)',
         offerPrice: '7.000',
         nego: true,
         url_loction: 'http .....',
         price: '8.000',
         unit: 'Ton',
         color:'blue',
         product: 'Fosfat',
         amount_product: '1203 ton'
      },
      {
         img: 'https://www.apni.or.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.d7c519b4.png&w=256&q=75',
         name: 'PT. NIKEL NUSANTARA MANDIRI',
         noTrx: '24072023004',
         status: 'Negotiation',
         statusColor: '#0052f1',
         bgColor: 'rgb(98 131 255 / 24%)',
         offerPriceColor: '(nego)',
         offerPrice: '8.000',
         nego: true,
         url_loction: 'http .....',
         price: '11.000',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '300 ton'
      },
      {
         img: 'https://imip.co.id/assets/uploads/logo.png',
         name: 'PT IMIP',
         noTrx: '24072023005',
         status: 'Shipped',
         statusColor: '#ff9100',
         bgColor: 'rgb(255 160 85 / 27%)',
         offerPriceColor: '(Deal)',
         offerPrice: '4.000',
         nego: false,
         url_loction: 'http .....',
         price: '14.000',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '993 ton'
      },
      {
         img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAvCAMAAADuOqUiAAAA0lBMVEX///8Aj4PrrBJ0dXjR0dJ/gIPp6eqFhogAjIDy8vLj4+OnqKp3eHuhoaT8/PzqqQBtbnH1rgDd3d6MjY+1trfx+fjq9PMAh3r99uad0Mvf8O68vb7R6efKysuSkpSy2tduvrfE4t4SnJFPr6f+/PYuoZf66cr33rH11ZbxxF/00Yn226j88tvxxGbtsyTxyHJqs6xIpJqKyMLuuEHwv07557/I4NTuuTNXm2/Dqi6qozwXkneWn03Mskh0m13YqRCNok/V49A8lHN/nVRnm1qxpSux69juAAADmUlEQVRYhcWYa3+aMBTGgYCABlC8ICKI117crNp1bXfr1q7f/yvtJAFFTHAr2D1vGmx++efkOecElaT/rG5vMPLjOPbHve77QQejeOJ5Bsjz5OlocAI96/dn5an20J8DUU5lGHN/IJ7eX1xcXl1dLxf9ctiev8lAE/LGF4T8Yflx5VCtbu7KBD1YyzkqJcvrIWfybHmlOEoiZ7UsAR7wsIS8GR3NvYNYlZ2c+8XbsZLt87myMdkezuxf32eowL0ogYVkjj0BWI6zJl/cKgdyLkth4aSngohlY91LJy0+Oc4h9qZkPkvSeCICexuSXbPZXZ6qOLdlzE3kC7AQ8fxzfwG+5qiKsipnLlN3LQY/PB5DKzCXabApAH9pHWNvKuiTRGMhV5a/flPy5n4vnVOpfEExUf14zIbcevr5qyqsJAmLiej55anFYnZaypcH366O2xUWEyP/flVardbT68uzF1d6Qw8LuXsZ84JL8i0SNeocdjKuFguN+q/AVZrLJG7UGVVrLtN4fhI8rdhcKlvcqBMdmuuqqpuOVVWtsUXgw9QKMzMmD4lqeXA3PmXuwfRA06J0URiyPag4HUlSva01zN3OdI0Ju1JeBY0aZMSHOeXqCKvJFhCy2D8jhFGQcnVk7bkaQk2iDiczh0XYST6nIqCxURtpIUMhjJFV43MjNQzVOrcgtuJG7R3llIqRXqcADekM0IBwcXoKea7FIyYSFpN3/IIpwUF3GA3Rv+Az0uAhcfVfuLagmPLmUoGtESxsthFmR9vBwExP4ZhrEgkaz5B7QxjTHmeuiaivIVjHNg2O100LNQObw8U0r7S6IGJeFRtz3vcHSbLgTG27gZpqGpNObY9MHheTMmoflW+i7paTyhxz6cpN1HbrkM00PtgAisKwA5G5HC7N51A1+UtxG/VW1JbbCAUBbrKscsFnOEtSSg2bwy3KK6KjRs01lypsogjY7OwCjGlnIOTaG7g5i0XmEpk6bRT0mGuQUGoN5EaQWQl3l73AbZzi5hq1L55pd5oQ5C6rIoYJMNIYV7caRCrLOTq2hAaTRp35ASAu2mIdI9SmK5mNtH6I0WQvwGUi/pP+zB5ECU20/9JkzIuwcO/pKLQZTEuaJSS2pln0PkoUZO+jgnj3FhuTU1e9mVSGDc7ubl4Ys0+YzOxD8ZtS+rolqNyzqccs3lb+HndKxOLMV+/300g2NuLKPZ/sragtn1mD8bube179ARA6QgytE2OmAAAAAElFTkSuQmCC',
         name: 'PT Vale Indonesia Tbk',
         noTrx: '24072023001',
         status: 'Success',
         statusColor: '#00b88a',
         bgColor: 'rgb(0 241 56 / 30%)',
         url_loction: 'http .....',
         offerPriceColor: '(Deal)',
         offerPrice: '6.000',
         nego: false,
         price: '9.000',
         unit: 'Ton',
         color:'orange',
         product: 'Nikel',
         amount_product: '300 ton'
      },
      {
            img: 'https://huadi.co.id/wp-content/uploads/2022/12/wadi-niki.png',
            name: 'PT HNI',
            noTrx: '24072023002',
            status: 'Proccess',
            statusColor: '#2e9097',
            bgColor: 'rgb(0 241 199 / 30%)',
            offerPriceColor: '(Deal)',
            offerPrice: '6.000',
            nego: false,
            url_loction: 'http .....',
            price: '8.000',
            unit: 'Ton',
            color:' red',
            product: 'Batu Bara',
            amount_product: '300 ton'
         },
      {
         img: 'https://media.licdn.com/dms/image/C4D0BAQFckBObXi37eA/company-logo_200_200/0/1519952060656?e=1684972800&v=beta&t=1_ogqayZQOaA87y9zrQC2Qc2Fl4AKChcr9ZA2ZvJSkM',
         name: 'Pabrik Nikel PT.SMI',
         noTrx: '24072023003',
         status: 'Failed',
         statusColor: '#f00',
         bgColor: 'rgb(243 105 105 / 16%)',
         offerPriceColor: '(failed)',
         offerPrice: '7.000',
         nego: true,
         url_loction: 'http .....',
         price: '8.000',
         unit: 'Ton',
         color:'blue',
         product: 'Fosfat',
         amount_product: '1203 ton'
      },
      {
         img: 'https://www.apni.or.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.d7c519b4.png&w=256&q=75',
         name: 'PT. NIKEL NUSANTARA MANDIRI',
         noTrx: '24072023004',
         status: 'Negotiation',
         statusColor: '#0052f1',
         bgColor: 'rgb(98 131 255 / 24%)',
         offerPriceColor: '(nego)',
         offerPrice: '8.000',
         nego: true,
         url_loction: 'http .....',
         price: '11.000',
         unit: 'Ton',
         color:'grey',
         product: 'Biji Besi',
         amount_product: '300 ton'
      }
   ],
   defaultManagementUser: [
      {
        "am_id": 1,
        "access_name": "Dashboard",
        "id": 1,
        "access_main": "Dashboard",
        "children": [
          {
            "access_name": "Dashboard",
            "am_id": 1,
            "amf_id": 10,
            "checked": false,
            "desc": "Show Status Transaksi",
            "future_name": "Status Transaksi",
            "main_id": 1
          }
        ]
      },
      {
        "am_id": 2,
        "access_name": "Bidding",
        "id": 2,
        "access_main": "Bidding",
        "children": [
          {
            "access_name": "Bidding",
            "am_id": 2,
            "amf_id": 20,
            "checked": true,
            "desc": "Show View Bidding",
            "future_name": "View Bidding",
            "main_id": 1
          },
          {
            "access_name": "Bidding",
            "am_id": 2,
            "amf_id": 21,
            "checked": true,
            "desc": "Can Bid Transactions",
            "future_name": "Bid Transaction",
            "main_id": 1
          }
        ]
      },
      {
        "am_id": 3,
        "access_name": "Transaction",
        "id": 3,
        "access_main": "Transaction",
        "children": [
          {
            "access_name": "Transaction",
            "am_id": 3,
            "amf_id": 30,
            "checked": true,
            "desc": "Can View data Transaction",
            "future_name": "View Transaction",
            "main_id": 1
          },
          {
            "access_name": "Transaction",
            "am_id": 3,
            "amf_id": 31,
            "checked": true,
            "desc": "Can Negotiation ",
            "future_name": "Negotiation",
            "main_id": 1
          }
        ]
      },
      {
        "am_id": 5,
        "access_name": "Fleet",
        "id": 5,
        "access_main": "Fleet",
        "children": [
          {
            "access_name": "Fleet",
            "am_id": 5,
            "amf_id": 50,
            "checked": true,
            "desc": "Can View Fleet data",
            "future_name": "View Fleet",
            "main_id": 1
          },
          {
            "access_name": "Fleet",
            "am_id": 5,
            "amf_id": 51,
            "checked": true,
            "desc": "Can Add Fleet data",
            "future_name": "Add Fleet",
            "main_id": 1
          },
          {
            "access_name": "Fleet",
            "am_id": 5,
            "amf_id": 52,
            "checked": true,
            "desc": "Can Update Status Fleet Active or Non Action or update data Fleet",
            "future_name": "Update Fleet",
            "main_id": 1
          }
        ]
      },
      {
        "am_id": 6,
        "access_name": "Approver",
        "id": 6,
        "access_main": "Approver",
        "children": [
          {
            "access_name": "Approver",
            "am_id": 6,
            "amf_id": 60,
            "checked": true,
            "desc": "Can Approve Transaction",
            "future_name": "Approver Transaction",
            "main_id": 2
          }
        ]
      },
      {
        "am_id": 7,
        "access_name": "Management User",
        "id": 7,
        "access_main": "Management User",
        "children": [
          {
            "access_name": "Management User",
            "am_id": 7,
            "amf_id": 70,
            "checked": true,
            "desc": "Can Setting Access Management",
            "future_name": "Setting Access Management",
            "main_id": 2
          }
        ]
      },
      {
        "am_id": 8,
        "access_name": "Setting",
        "id": 8,
        "access_main": "Setting",
        "children": [
          {
            "access_name": "Setting",
            "am_id": 8,
            "amf_id": 80,
            "checked": false,
            "desc": "Can Edit Company",
            "future_name": "Edit Company",
            "main_id": 3
          }
        ]
      }
    ]
};

export default Constant;

